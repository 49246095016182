import { useState } from "react";
import { Layout, DatePicker } from "components";
import { useQuery } from "react-query";
import { getSuperadminSalesStats, queryKeys } from "constant";
import moment from "moment";
import { ImFileEmpty } from "react-icons/im";

function SuperAdminHomePage() {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [page, setPage] = useState(1);
  const { data, isLoading }: any = useQuery(
    [
      queryKeys.GET_SUPER_ADMIN_SALES_STATS,
      {
        startTime,
        endTime,
        limit: 5,
        page,
      },
    ],
    getSuperadminSalesStats
  );

  const {
    totalRevenue = 0,
    companiesCount = 0,
    totalDocsVerified = 0,
    allDocumentsData = [],
  } = data?.data || {};

  return (
    <Layout>
      <div className="datepicker-wrap">
        <DatePicker
          onChange={(dateRange) => {
            setStartTime(dateRange.startDate * 1000);
            setEndTime(dateRange.endDate * 1000);
          }}
        />
      </div>
      <div className="">
        <div className="verify-doc-wrapper sales-home-3-box grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="p-6 verify-doc-box sales-home-top-box flex justify-between items-center">
            <span className="svg-icon svg-icon-2x svg-icon-info flex items-center justify-center">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 1024 1024"
                className="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M531.8 385v483.3h0.1V385h-0.1z" fill="#343535" />
                <path
                  d="M670.9 497.1h86v16h-86zM670.9 625.1h86v16h-86zM233.9 241.1h86v16h-86zM384 241.1h86v16h-86zM233.9 369h86v16h-86zM384 369h86v16h-86zM234 497.5h86v16h-86zM384 497.2h86v16h-86z"
                  fill="#39393A"
                />
                <path
                  d="M398.3 704.4c-11.9-11.9-28.4-19.3-46.5-19.3-36.2 0-65.8 29.6-65.8 65.8v117.4h20V750.9c0-12.2 4.8-23.6 13.5-32.3 8.7-8.7 20.2-13.5 32.3-13.5 12.2 0 23.6 4.8 32.3 13.5 8.7 8.7 13.5 20.2 13.5 32.3v117.4h20V750.9c0-18.1-7.4-34.5-19.3-46.5z"
                  fill="#E73B37"
                />
                <path
                  d="M575.8 429v437.9h0.1V429h-0.1zM286.2 868.3h131.6-131.6z"
                  fill="#343535"
                />
                <path
                  d="M896 868.3V385H575.9V111.6H128v756.7H64v44h896v-44h-64z m-364.1 0H172V155.6h359.9v712.7z m320.1-1.5H575.8V429H852v437.8z"
                  fill="#39393A"
                />
              </svg>
            </span>

            <span>
              <h2>{companiesCount}</h2>
              <p>Total companies</p>
            </span>
          </div>

          <div className="p-6 verify-doc-box sales-home-top-box flex justify-between items-center">
            <span className="svg-icon svg-icon-2x svg-icon-info flex items-center justify-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  opacity="0.3"
                  d="M12.5 22C11.9 22 11.5 21.6 11.5 21V3C11.5 2.4 11.9 2 12.5 2C13.1 2 13.5 2.4 13.5 3V21C13.5 21.6 13.1 22 12.5 22Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M17.8 14.7C17.8 15.5 17.6 16.3 17.2 16.9C16.8 17.6 16.2 18.1 15.3 18.4C14.5 18.8 13.5 19 12.4 19C11.1 19 10 18.7 9.10001 18.2C8.50001 17.8 8.00001 17.4 7.60001 16.7C7.20001 16.1 7 15.5 7 14.9C7 14.6 7.09999 14.3 7.29999 14C7.49999 13.8 7.80001 13.6 8.20001 13.6C8.50001 13.6 8.69999 13.7 8.89999 13.9C9.09999 14.1 9.29999 14.4 9.39999 14.7C9.59999 15.1 9.8 15.5 10 15.8C10.2 16.1 10.5 16.3 10.8 16.5C11.2 16.7 11.6 16.8 12.2 16.8C13 16.8 13.7 16.6 14.2 16.2C14.7 15.8 15 15.3 15 14.8C15 14.4 14.9 14 14.6 13.7C14.3 13.4 14 13.2 13.5 13.1C13.1 13 12.5 12.8 11.8 12.6C10.8 12.4 9.99999 12.1 9.39999 11.8C8.69999 11.5 8.19999 11.1 7.79999 10.6C7.39999 10.1 7.20001 9.39998 7.20001 8.59998C7.20001 7.89998 7.39999 7.19998 7.79999 6.59998C8.19999 5.99998 8.80001 5.60005 9.60001 5.30005C10.4 5.00005 11.3 4.80005 12.3 4.80005C13.1 4.80005 13.8 4.89998 14.5 5.09998C15.1 5.29998 15.6 5.60002 16 5.90002C16.4 6.20002 16.7 6.6 16.9 7C17.1 7.4 17.2 7.69998 17.2 8.09998C17.2 8.39998 17.1 8.7 16.9 9C16.7 9.3 16.4 9.40002 16 9.40002C15.7 9.40002 15.4 9.29995 15.3 9.19995C15.2 9.09995 15 8.80002 14.8 8.40002C14.6 7.90002 14.3 7.49995 13.9 7.19995C13.5 6.89995 13 6.80005 12.2 6.80005C11.5 6.80005 10.9 7.00005 10.5 7.30005C10.1 7.60005 9.79999 8.00002 9.79999 8.40002C9.79999 8.70002 9.9 8.89998 10 9.09998C10.1 9.29998 10.4 9.49998 10.6 9.59998C10.8 9.69998 11.1 9.90002 11.4 9.90002C11.7 10 12.1 10.1 12.7 10.3C13.5 10.5 14.2 10.7 14.8 10.9C15.4 11.1 15.9 11.4 16.4 11.7C16.8 12 17.2 12.4 17.4 12.9C17.6 13.4 17.8 14 17.8 14.7Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>

            <span>
              <h2>{totalRevenue}</h2>
              <p>Total revenue</p>
            </span>
          </div>

          <div className="p-6 verify-doc-box sales-home-top-box flex justify-between items-center">
            <span className="svg-icon svg-icon-2x svg-icon-info flex items-center justify-center">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M5 8C5 5.17157 5 3.75736 5.87868 2.87868C6.75736 2 8.17157 2 11 2H13C15.8284 2 17.2426 2 18.1213 2.87868C19 3.75736 19 5.17157 19 8V16C19 18.8284 19 20.2426 18.1213 21.1213C17.2426 22 15.8284 22 13 22H11C8.17157 22 6.75736 22 5.87868 21.1213C5 20.2426 5 18.8284 5 16V8Z"
                    stroke="#1C274C"
                    stroke-width="1.5"
                  ></path>{" "}
                  <path
                    opacity="0.7"
                    d="M9 13H15"
                    stroke="#1C274C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>{" "}
                  <path
                    d="M9 9H15"
                    stroke="#1C274C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>{" "}
                  <path
                    opacity="0.4"
                    d="M9 17H12"
                    stroke="#1C274C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>{" "}
                  <path
                    opacity="0.5"
                    d="M2 19V5"
                    stroke="#1C274C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>{" "}
                  <path
                    opacity="0.5"
                    d="M22 19V5"
                    stroke="#1C274C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </span>

            <span>
              <h2>{totalDocsVerified}</h2>
              <p> Total docs verified</p>
            </span>
          </div>
        </div>

        <div className="p-7 body-bg">
          <div className="all-verified-documents-wrap flex p-7 flex-col">
            <div className="flex justify-between items-center mb-6">
              <h2 className="">All verified documents</h2>
              <div className="flex items-center gap-2"></div>
            </div>

            <div className="table-container w-full">
              <table className="w-full">
                <tbody>
                  <tr className="head-dashed-underline">
                    <th className="uppercase">Transaction Id</th>
                    <th className="uppercase">Company Id</th>
                    <th className="uppercase">Status</th>
                    <th className="uppercase">Timestamp</th>
                    <th className="uppercase">Doc Type</th>
                    <th className="uppercase">Doc Id</th>
                  </tr>
                  {isLoading && <div className="text-white">Loading...</div>}
                  {allDocumentsData?.map((item: any) => {
                    const {
                      transactionId,
                      companyId,
                      status,
                      createdAt,
                      meta,
                    } = item;

                    return (
                      <tr>
                        <td>{transactionId}</td>
                        <td>{companyId}</td>
                        <td>{status}</td>
                        <td>{moment(createdAt).format("DD-MM-YYYY")}</td>
                        <td>{meta?.documentType}</td>
                        <td>********{meta?.documentId}</td>
                      </tr>
                    );
                  })}
                </tbody>

                {allDocumentsData?.length == 0 && !isLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
            <div
              onClick={() => {
                setPage((old) => old - 1);
              }}
              className="p-2 rounded text-white back-bt"

            >
              Back
            </div>
            <div
              onClick={() => {
                setPage((old) => old + 1);
              }}
              className="p-2 rounded text-white next-bt"

            >
              Next
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export { SuperAdminHomePage };
