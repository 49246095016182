import { useRef, useState } from "react";
import { Layout } from "components";
import { useMutation } from "react-query";
import { requestCashPayment, allCashPaymentRequests } from "constant";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "react-query";
import { MoonLoader, ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

function CashPayment() {
  const phoneRef: any = useRef();
  const tokensRef: any = useRef();
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);
  const {
    data,
    refetch,
    isLoading: isDataLoading,
  } = useQuery(
    [
      "cash-payment-requests",
      {
        limit: 5,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    allCashPaymentRequests
  );
  const { mutate: requestPayment, isLoading } = useMutation({
    mutationFn: requestCashPayment,
    onSuccess: async (data) => {
      if (
        data?.message.includes("valid") ||
        data?.message.includes("missing")
      ) {
        toast.error(data?.message);
      } else {
        toast.success("Payment requested");
        refetch();
      }
    },
  });

  return (
    <Layout>
      <div className="text-white bg-mob send-token-mob-wrapper">
        <div className="p-16 sm:p-8 all-verified-documents-wrap all-verified-documents-wrap-mob m-8 max-w-fit">
          <h1 className="text-3xl font-bold mb-6">Send Tokens</h1>
          <div className="my-2 font-bold">Enter Phone Number</div>
          <input
            ref={phoneRef}
            className="mb-6 text-black w-96 mt-1 p-2 block border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
          <div className="my-2 font-bold">Number Of Tokens</div>
          <input
            ref={tokensRef}
            className="text-black w-96 mt-1 p-2 block border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
          <br />
          <button
            className="py-3 px-4 rounded font-bold text-white bg-blue-500 mt-2 mb-10 cursor-pointer rq-mob-bt"
            onClick={() => {
              requestPayment({
                tokens: Number(tokensRef.current.value),
                paymentForPhone: phoneRef.current.value,
              });
              phoneRef.current.value = "";
              tokensRef.current.value = "";
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <div>
                <ClockLoader color="white" size="20" />
              </div>
            ) : (
              "Request Gift"
            )}
          </button>{" "}
        </div>

        <div className="all-verified-documents-wrap m-8 p-10">
          <div className="mb-6 font-bold text-xl">All Gift Requests</div>
          <div className="table-container w-full">
            <table className="w-full">
              <thead>
                <tr className="head-dashed-underline">
                  <th className="uppercase text-left">Request for</th>
                  <th className="uppercase">Request tokens</th>
                  <th className="uppercase">Reseller id</th>
                  <th className="uppercase">Approved</th>
                  <th className="uppercase">Company Name</th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.Items?.map((item) => (
                  <tr>
                    <td>{item?.requestedForUserId}</td>
                    {/* <td>{moment(company?.createdAt).format("DD/MM/YYYY")}</td> */}
                    <td>{item?.requestedTokens}</td>
                    <td>{item?.resellerId}</td>
                    <td>
                      {item?.isApproved ? (
                        <div className="p-1 font-bold bg-green-600 text-white rounded max-w-fit">
                          Yes
                        </div>
                      ) : (
                        <div className="p-1 font-bold bg-red-600 text-white rounded max-w-fit">
                          No
                        </div>
                      )}
                    </td>
                    <td>{item?.companyMeta?.name}</td>
                  </tr>
                ))}

                {data?.data?.Items?.length == 0 && (
                  <tr>
                    <td colSpan={10}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isDataLoading && (
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
            <div
              className="flex items-center"
              onClick={() => {
                setLastEvaluatedKey((olds: any) => {
                  const newArr = JSON.parse(JSON.stringify(olds));
                  return newArr?.length ? newArr?.slice(0, -1) : newArr;
                });
              }}
              style={{
                backgroundColor: LastEvaluatedKeys?.length
                  ? "#009ef7"
                  : "#2b2b40",
                color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
              }}
            >
              Back
            </div>
            <div
              onClick={() => {
                if (data?.data?.LastEvaluatedKey) {
                  setLastEvaluatedKey((olds) => {
                    return [...olds, data?.data?.LastEvaluatedKey];
                  });
                }
              }}
              className={`flex items-center`}
              style={{
                backgroundColor: data?.data?.LastEvaluatedKey
                  ? "#009ef7"
                  : "#2b2b40",
                color: data?.data?.LastEvaluatedKey ? "white" : "#7e8299",
              }}
            >
              Next
            </div>
          </div>
        </div>
      </div>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { CashPayment };
