import { Layout } from "components";
import React from "react";

function CouponDetails() {
  return (
    <Layout>
      <div>CouponDetails</div>
    </Layout>
  );
}

export { CouponDetails };
