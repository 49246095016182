import { Layout } from "components";
import {
  documentEntry,
  documentTypeMap,
  queryKeys,
  userDetails,
  getWalletDetails,
  documentModeMap,
} from "constant";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { MoonLoader, ClockLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import { DocumentDisplayCard } from "components/DocumentDisplayCard";
import moment from "moment";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

function DLVerify() {
  const drivingLicenseSchema = Yup.object().shape({
    drivingLicense: Yup.string().matches(
      /^(([A-Za-z]{2}[0-9]{2})|([A-Za-z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
      { message: "Please enter valid number.", excludeEmptyString: false }
    ),
    dob: Yup.string().required("DOB is required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(drivingLicenseSchema),
  });

  const [showData, setShowData] = useState<any>();
  const [searchParams] = useSearchParams();

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );

  const { refetch: refetchWalletDetails } = useQuery(
    [
      queryKeys.GET_WALLET_DETAILS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getWalletDetails,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  const { mutate: mutateDocument, isLoading } = useMutation({
    mutationFn: documentEntry,
    onSuccess: (data) => {
      if (
        (data?.data?.mapId === documentTypeMap.AADHAR ||
          data?.data?.mapId === documentTypeMap.DL ||
          data?.data?.mapId === documentTypeMap.PAN ||
          data?.data?.mapId === documentTypeMap.DIGI_EAADHAR) &&
        data?.data?.verified === "true"
      ) {
        toast.success("Document Verification Successful");
        setShowData(data);
      } else if (data?.data?.mapId === documentTypeMap.DIGI_URL) {
        localStorage.setItem("digiRequestId", data?.data?.requestId);
        window.location.replace(data?.data?.url);
      } else {
        toast.error("Third Party Server Error");
      }
      reset();
      refetchWalletDetails();
    },
    onError: () => {
      alert("Third Party Server Error");
    },
  });

  useEffect(() => {
    if (
      searchParams.get("confirmAuthorization") == "true" &&
      userData?.data?.companyId
    ) {
      mutateDocument({
        documentId: localStorage.getItem("digiRequestId"), //  here document id is requestId
        companyId: userData?.data?.companyId,
        documentType: documentTypeMap.DL,
        mapId: documentTypeMap.DL,
      });
    }
  }, [userData]);

  const handleSub = (values) => {
    mutateDocument({
      documentId: values.drivingLicense.toUpperCase(),
      companyId: userData?.data?.companyId,
      documentType: documentTypeMap.DL,
      mapId: documentTypeMap.DL,
      dob: moment(values.dob).format("DD/MM/YYYY"),
    });
  };
  return (
    <Layout>
      <div className="veriication-detail-wrapper flex flex-col p-5 xl:p-7">
        <div className="document-type-box document-type-pan flex p-4">
          <h2>Document Type - Driving license</h2>
        </div>

        <form onSubmit={handleSubmit(handleSub)}>
          <div className="verify-box mt-7">
            <h2>Verify with DL number</h2>

            <div className="adhaar-no-box">
              <h3>Enter Driving License Number</h3>
              <div className="adhaar-putin-box">
                <input
                  {...register("drivingLicense")}
                  placeholder="Ex: WB2520201234567"
                  className={clsx(
                    "border-2 w-full",
                    { "is-invalid": errors && errors.drivingLicense },
                    { "is-valid": !errors && errors.drivingLicense }
                  )}
                  type="text"
                  autoComplete="off"
                />

                <p className="mt-1 mb-2">
                  Enter a valid number for Driving License verification.
                </p>
                <div>
                  <span role="alert">{errors.drivingLicense?.message}</span>
                </div>
              </div>

              <h3>Choose DOB</h3>
              <div className="adhaar-putin-box">
                <input
                  {...register("dob")}
                  max={moment().format("YYYY-MM-DD")}
                  className={clsx(
                    "border-2 w-full",
                    { "is-invalid": errors && errors.dob },
                    { "is-valid": !errors && errors.dob }
                  )}
                  type="date"
                  autoComplete="off"
                />
                <div>
                  <span role="alert">{errors.dob?.message}</span>
                </div>
                <button className="blue-bt mt-5" disabled={isLoading}>
                  {isLoading ? (
                    <div>
                      <ClockLoader color="white" size="20" />
                    </div>
                  ) : (
                    "Verify here"
                  )}
                </button>
              </div>

              <div>After verification details are here</div>
              {showData ? (
                <DocumentDisplayCard
                  isNoWrap
                  doc={{
                    addedBy: userData?.data,
                    createdAt: Date.now(),
                    mode: documentModeMap[documentTypeMap.DL].mode,
                    meta: showData?.data,
                  }}
                />
              ) : null}
            </div>
          </div>
        </form>
      </div>

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { DLVerify };
