import { DataField } from ".";
import moment from "moment";

function DrivingLicenseCard({ doc }) {
  const { addedBy, createdAt, meta } = doc;
  const { address, bloodGroup, dob, img, name, status } = meta;

  return (
    <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
      <p className="adhaar-data-box flex self-start">Driving license</p>
      <div className="customer-details-top">
        <DataField title="">
          <img src={img} />
        </DataField>
        <DataField title="Name">{name}</DataField>
        <DataField title="Date of birth">{dob}</DataField>
        <DataField title="Document verified on">
          {moment(createdAt).format("DD/MM/YYYY")}
        </DataField>
        <DataField title="Document verified by">
          {`${addedBy.name} (${addedBy.role})`}
        </DataField>
        <DataField title="Address">{address[0].completeAddress}</DataField>
        <DataField title="Blood Group">{bloodGroup}</DataField>
        <DataField title="Document status">{status}</DataField>
      </div>
    </div>
  );
}

export default DrivingLicenseCard;
