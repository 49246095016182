import { cookie } from "constant";

async function doProtectedPost(url: string, body?: any) {
  const authToken = cookie.get("accessToken");
  if (!authToken?.length) window.location.replace("/login");

  const obj: any = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  if (body) obj.body = JSON.stringify(body);
  return fetch(url, obj).then(async (res) => res.json());
}

async function doProtectedGet(url: string) {
  const authToken = cookie.get("accessToken");
  if (!authToken?.length) window.location.replace("/login");

  const obj: any = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  return fetch(url, obj).then((res) => res.json());
}

async function doOpenGet(url: string) {
  const obj: any = {
    method: "GET",
  };
  return fetch(url, obj).then((res) => res.json());
}

async function doOpenPost(url, body?: any) {
  const obj: any = {
    method: "POST",
  };
  if (body) obj.body = JSON.stringify(body);
  return fetch(url, obj).then((res) => res.json());
}

export { doProtectedPost, doProtectedGet, doOpenPost, doOpenGet };
