import moment from "moment";
import { CustomerData } from "pages/types";
import { useEffect, useRef, useState } from "react";
import { Button, Popover } from "@radix-ui/themes";
import { DateRange } from "react-date-range";

function EditCustomerDetails({
  customer,
  onUpdate,
  onClose,
  isUpdatingCustomer,
}: {
  customer: CustomerData;
  onUpdate: (data: {
    roomType: string;
    roomNumber: string;
    checkoutDate: number;
    advancedPaid: number;
    entryDate: number;
    noOfDays: number;
    dailyCost: number;
  }) => any;
  onClose: () => any;
  isUpdatingCustomer?: boolean;
}) {
  const { meta, returnDate, entryDate, createdAt, ...restObj } = customer;
  const { name, advancedPaid, dailyCost, roomType, roomNumber, noOfDays } =
    meta;

  const [dateRange, setDateRange] = useState({
    startDate: entryDate || createdAt,
    endDate: returnDate,
    key: "selection",
  });

  const [advancePaid, setAdvancePaid]: any = useState(advancedPaid);

  let getNoDays = moment(dateRange.endDate).diff(
    moment(dateRange.startDate).startOf("day"),
    "days"
  );

  const [showPicker, setShowPicker] = useState(false);

  const roomTypeRef: any = useRef();
  const roomNumberRef: any = useRef();

  const handleUpdate = () => {
    onUpdate({
      roomType: roomTypeRef?.current?.value,
      roomNumber: roomNumberRef?.current?.value,
      checkoutDate: moment(dateRange.endDate).unix() * 1000,
      entryDate: moment(dateRange.startDate).unix() * 1000,
      advancedPaid: advancePaid,
      noOfDays: getNoDays,
      dailyCost,
    });
  };

  return (
    <div className="modal-wrapper flex flex-col">
      <div className="modal-title flex p-6 w-full">
        Customer Documents Details
      </div>

      <div className="modal-body flex flex-col pt-0  w-full">
        <div className="convert-clients-wrap p-8">
          <div className="flex flex-col">
            <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6 ">
                  Client Name: &nbsp;
                </span>
                {name}
              </div>
              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6">
                  Advanced Paid: &nbsp;
                </span>{" "}
                <b className={`text-${advancedPaid * 1 > 0 && "success"}`}>
                  {advancedPaid}
                </b>
              </div>
              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6">
                  No of Days: &nbsp;
                </span>
                {noOfDays}
              </div>

              <div className="flex justify-between text-white text-sm">
                <span className="fw-bold text-muted fs-6">
                  Remaining to be Paid: &nbsp;
                </span>{" "}
                <b>{noOfDays * dailyCost - advancedPaid}</b>
              </div>

              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Room Type
                </label>
                <input
                  defaultValue={roomType}
                  ref={roomTypeRef}
                  required={true}
                  placeholder="Update advanced paid"
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid"
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder text-muted fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Room Number
                </label>
                <input
                  ref={roomNumberRef}
                  defaultValue={roomNumber}
                  placeholder="Update advanced paid"
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid"
                  required={true}
                />
              </div>

              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Select stay period"}
                </label>
                <Popover.Root open={showPicker}>
                  <Popover.Trigger>
                    <Button
                      className="pick-date-range"
                      variant="soft"
                      onClick={() => setShowPicker(true)}
                    >
                      {moment(dateRange.startDate).format("DD-MM-YYYY")} to{" "}
                      {moment(dateRange.endDate).format("DD-MM-YYYY")}
                    </Button>
                  </Popover.Trigger>
                  <Popover.Content style={{ zIndex: 1000 }}>
                    <button
                      className="px-2 border float-right text-black"
                      onClick={() => {
                        setShowPicker(false);
                      }}
                    >
                      X
                    </button>
                    <DateRange
                      showDateDisplay={false}
                      showPreview={false}
                      showSelectionPreview={false}
                      showMonthAndYearPickers={false}
                      showMonthArrow={true}
                      ranges={[dateRange]}
                      minDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 1)
                        )
                      }
                      maxDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 1)
                        )
                      }
                      onChange={(data) => {
                        let { startDate, endDate } = data.selection;
                        setDateRange({
                          startDate,
                          endDate,
                          key: "selection",
                        });
                      }}
                    />
                  </Popover.Content>
                </Popover.Root>

                {(dateRange.startDate !== (entryDate || createdAt) ||
                  dateRange.endDate !== returnDate ||
                  advancePaid !== advancedPaid) && (
                  <div className="mt-3">
                    <p>Updated number of days: {getNoDays}</p>
                    <p>
                      Updated payment to receive:
                      {getNoDays * dailyCost - advancePaid}
                    </p>
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                <label
                  className="form-label fw-bolder text-muted fs-6 mb-2"
                  style={{ color: "white" }}
                >
                  Advanced Paid
                </label>
                <input
                  value={advancePaid}
                  onChange={(e) => {
                    setAdvancePaid(e.target.value);
                  }}
                  defaultValue={advancedPaid}
                  placeholder="Update advanced paid"
                  type="number"
                  autoComplete="off"
                  className="form-control form-control-lg form-control-solid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal-footer flex justify-end p-6 w-full gap-2">
        <a className="cancel-bt flex items-center" onClick={onClose}>
          Cancel
        </a>
        <button
          className="blue-bt flex items-center"
          onClick={handleUpdate}
          disabled={isUpdatingCustomer}
        >
          {isUpdatingCustomer ? "Updating..." : "Update"}
        </button>
      </div>
    </div>
  );
}

export default EditCustomerDetails;
