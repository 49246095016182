import { ResetFilterButton } from "components/ResetFilterButton";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRangePicker, Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Popover,
  Text,
  TextArea,
} from "@radix-ui/themes";
import { DateRangeUnix } from "pages/types";

interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

function DatePicker(props: {
  onChange: (dateRange: { startDate: number; endDate: number }) => any;
}) {
  // Constants
  const defaultDateRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  // Ui states
  const [showPicker, setShowPicker] = useState(false);
  const [showPickerReset, setShowPickerReset] = useState(false);
  const [showPickerApply, setShowPickerApply] = useState(false);

  // Ui data states
  const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange);
  const [dateRangeValue, setDateRangeValue] = useState<DateRangeUnix>({
    startDate: 0,
    endDate: 0,
  });

  function handleDateRangeSelection(range: DateRange) {
    setShowPickerReset(true);
    setDateRange(range);
  }

  function handleApplyFilter() {
    if (dateRange.startDate.getTime() == dateRange.endDate.getTime()) {
      props.onChange({
        startDate: moment(dateRange.startDate).unix(),
        endDate: moment(dateRange.startDate).endOf("day").unix(),
      });
    } else {
      props.onChange({
        startDate: moment(dateRange.startDate).unix(),
        endDate: moment(dateRange.endDate).endOf("day").unix(),
      });
    }
    setDateRangeValue({
      startDate: dateRange.startDate.getTime(),
      endDate: dateRange.endDate.getTime(),
    });
    setShowPickerReset(true);
    setShowPicker(false);
  }

  function handleResetFilter() {
    props.onChange({
      startDate: 0,
      endDate: 0,
    });
    setDateRange(defaultDateRange);
    setShowPickerReset(false);
    setDateRangeValue({
      startDate: 0,
      endDate: 0,
    });
  }

  // Side effects
  useEffect(() => {}, [dateRange]);

  return (
    <Popover.Root
      onOpenChange={(open) => {
        if (!open) {
          handleApplyFilter();
          setShowPickerApply(false);
        } else {
          setShowPickerApply(true);
        }
      }}
      open={showPicker}
    >
      <Flex gap="3">
        <Popover.Trigger>
          {!dateRangeValue.startDate && !dateRangeValue.endDate ? (
            <Button
              className="pick-date-range capitalize"
              variant="soft"
              onClick={() => setShowPicker(true)}
            >
              Pick a date range
            </Button>
          ) : (
            <Button
              className="pick-date-range"
              variant="soft"
              onClick={() => setShowPicker(true)}
            >
              {moment(dateRangeValue.startDate).format("DD-MM-YYYY")} to{" "}
              {moment(dateRangeValue.endDate).format("DD-MM-YYYY")}
            </Button>
          )}
        </Popover.Trigger>

        {showPickerReset && <Button onClick={handleResetFilter}>Reset</Button>}
      </Flex>
      <Popover.Content>
        {/* <Flex gap="3">
          <Box grow="1">
            <TextArea placeholder="Write a comment…" style={{ height: 80 }} />
            <Flex gap="3" mt="3" justify="between">
              <Flex align="center" gap="2" asChild>
                <Text as="label" size="2">
                  <Checkbox />
                  <Text>Send to group</Text>
                </Text>
              </Flex>

              <Popover.Close>
                <Button size="1">Comment</Button>
              </Popover.Close>
            </Flex>
          </Box>
        </Flex> */}
        <Flex gap="3" direction={"column"}>
          <div>
            <DateRange
              showDateDisplay={false}
              showPreview={false}
              showSelectionPreview={false}
              showMonthAndYearPickers={false}
              showMonthArrow={true}
              ranges={[dateRange]}
              onChange={(data) => handleDateRangeSelection(data.selection)}
            />
          </div>
          <Flex justify={"center"} gap="4">
            <Button onClick={handleApplyFilter}>Apply</Button>
            <Button
              onClick={() => {
                setShowPicker(false);
                setDateRangeValue({
                  endDate: 0,
                  startDate: 0,
                });
                props.onChange({
                  startDate: 0,
                  endDate: 0,
                });
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
}

export { DatePicker };
