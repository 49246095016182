import { Layout } from "components";
import moment from "moment";
import { CustomerData } from "pages/types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditCustomerDetails from "./EditCustomerDetails";
import { Modal } from "react-responsive-modal";
import DocumentCard from "./DocumentCard";
import { useMutation, useQuery } from "react-query";
import { getCustomers, queryKeys, updateCustomer, userDetails } from "constant";
import toast, { Toaster } from "react-hot-toast";

function CustomerDetailsPage() {
  const { state } = useLocation();
  const [customer, setCustomer] = useState<CustomerData>(state);
  console.log("Customer data", customer);

  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const closeEditModal = () => setEditModal(false);
  const openEditModal = () => setEditModal(true);

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  const {
    data: customersData,
    refetch: customersDataRefetch,
    isLoading: isCustomersDataLoading,
  } = useQuery(
    [
      queryKeys.GET_CUSTOMERS,
      {
        companyId: userData?.data?.companyId,
      },
    ],
    getCustomers,
    { enabled: !!userData?.data?.userId?.length }
  );

  useEffect(() => {
    if (customersData) {
      customersData?.data?.Items?.forEach((customerData) => {
        if (customerData.customerId == customer.customerId) {
          setCustomer(customerData);
        }
      });
    }
  }, [customersData]);

  const { mutate: updateCustomerMutate, isLoading: isUpdatingCustomer } =
    useMutation({
      mutationFn: updateCustomer,
      onSuccess: (data) => {
        if (data.code != 200) {
          closeEditModal();
          toast.error(data.message);
        } else {
          closeEditModal();
          toast.success(data.message);
          customersDataRefetch();
        }
        console.log("Customer update details", data);
      },
      onError: () => {
        toast.error("Cannot update customer");
      },
    });

  return (
    <Layout>
      <div className="convert-clients-wrap">
        <div className="p-7 body-bg">
          <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
            <div className="flex justify-between mb-5 gap-4 flex-col md:flex-row xl:flex-row md:gap-0 xl:gap-0">
              <h2>Customer Detailed Info:</h2>
              <div className="flex flex-row justify-end gap-4">
                <button
                  className="assign-bt flex items-center"
                  onClick={openEditModal}
                >
                  Edit
                </button>
                <button
                  className="back-bt flex items-center"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="customer-details-top">
                <span className="flex">
                  <h3> Customer Name</h3>
                  <h4>{customer.meta.name}</h4>
                </span>
                <span className="flex">
                  <h3> Status</h3>
                  <div
                    className={`text-sm font-bold text-${
                      customer.isActive &&
                      customer.returnDate >
                        (customersData?.data?.currentTime || Date.now())
                        ? "green-600"
                        : "red-600"
                    }`}
                  >
                    {customer.isActive &&
                    customer.returnDate >
                      (customersData?.data?.currentTime || Date.now())
                      ? "Active"
                      : "Inactive"}
                  </div>
                </span>
                <span className="flex">
                  <h3> No Of Days</h3>
                  <h4>{customer.meta.noOfDays}</h4>
                </span>

                <span className="flex">
                  <h3> Daily Cost (₹)</h3>
                  <h4>{customer.meta.dailyCost}</h4>
                </span>
                <span className="flex">
                  <h3> Checked In</h3>
                  <h4> {moment(customer.entryDate).format("Do MMMM YYYY")}</h4>
                </span>
              </div>

              <div className="customer-details-top">
                <span className="flex">
                  <h3>Customer Contact(+91)</h3>
                  <h4>{customer?.meta?.phoneNumber || customer?.phone}</h4>
                </span>

                <span className="flex">
                  <h3>Room Type & Number</h3>
                  <h4>
                    {customer.meta.roomType} - {customer.meta.roomNumber}
                  </h4>
                </span>
                <span className="flex">
                  <h3>Advanced Paid(₹)</h3>
                  <h4>{Number(customer.meta.advancedPaid)}</h4>
                </span>

                <span className="flex">
                  <h3> Amount Due(₹)</h3>
                  <h4 className="text-amber-300">
                    {" "}
                    {customer.meta.noOfDays * customer.meta.dailyCost -
                      customer.meta.advancedPaid}
                  </h4>
                </span>

                <span className="flex">
                  <h3>Check Out</h3>
                  <h4 className="font-bold text-red-600">
                    {moment(customer.returnDate).format("Do MMMM YYYY")}
                  </h4>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="col-lg-6"></div>
          </div>

          <div style={{ marginTop: "2rem" }} className="clients-docs-container">
            <span>
              <h2 className="font-bold text-white">Customer Documents</h2>
            </span>
            {customer.documentEntryIds.map((documentId) => (
              <DocumentCard documentId={documentId} />
            ))}
          </div>
        </div>

        <Modal open={editModal} onClose={closeEditModal}>
          <EditCustomerDetails
            customer={customer}
            onUpdate={(data) => {
              const amountToBePaid =
                data.noOfDays * data.dailyCost - data.advancedPaid;
              if (amountToBePaid > -1 && data.noOfDays > 0) {
                updateCustomerMutate({
                  companyId: userData?.data?.companyId,
                  customerId: customer.customerId,

                  meta: {
                    ...customer.meta,
                    advancedPaid: data.advancedPaid,
                    roomType: data.roomType,
                    roomNumber: data.roomNumber,
                    noOfDays: data.noOfDays,
                  },
                  returnDate: data.checkoutDate,
                  entryDate: data.entryDate,
                });
                closeEditModal();
              } else {
                closeEditModal();
                toast.error(
                  "Form is not valid! make sure amount is not exceeding and no of days is greater than 0"
                );
              }
            }}
            onClose={closeEditModal}
            isUpdatingCustomer={isUpdatingCustomer}
          />
        </Modal>

        <Toaster
          toastOptions={{
            success: {
              duration: 3000,
            },
          }}
        />
      </div>
    </Layout>
  );
}

export { CustomerDetailsPage };
