import { VerifyOtp } from "components";
import { forgotPasswordRequest, forgotPasswordVerify } from "constant";
import { localStorageItems } from "constant/localStorageItem";
import moment, { now } from "moment";
import { Timer } from "pages/types";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation } from "react-query";
import { ClockLoader } from "react-spinners";
import { getMinutes } from "utils/timeUtils";

function ForgotPassword() {
  const inputModes = {
    phone: "PHONE",
    otp: "VERIFY_OTP",
  };
  const [inputMode, setInputMode] = useState(inputModes.phone);
  const [isRequestAvailable, setIsRequestAvailable] = useState(false);
  const [isVerifyRequestAvailable, setIsVerifyRequestAvailable] =
    useState(false);
  const [otpExpireTime, setOtpExpireTime] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");

  const phoneRef: any = useRef();
  const verifyPhoneRef: any = useRef();
  const otpRef: any = useRef();
  const newPasswordRef: any = useRef();
  const storedTimer = Timer.getOtpExpireTime();
  // const storedPhoneNumber = localStorage.getItem(localStorageItems.USER_PHONE);
  let otpValidTill = 0;

  const {
    mutate: mutateForgotPasswordRequest,
    isLoading: isForgotPasswordRequesting,
  } = useMutation({
    mutationFn: forgotPasswordRequest,
    onSuccess: (data) => {
      if (data?.code != 200) {
        toast.error(data?.message);
        return;
      }
      const { otpExpireTime: serverOtpExpireTime } = data?.data;
      setOtpExpireTime(serverOtpExpireTime);
      setInputMode(inputModes.otp);

      toast.success("Otp send");
    },
    onError: () => {
      toast.error("Failed to request action for forgot password");
    },
  });

  const {
    mutate: mutateForgotPasswordVerify,
    isLoading: isForgotPasswordVerifying,
  } = useMutation({
    mutationFn: forgotPasswordVerify,
    onSuccess: (data) => {
      console.log("Response data here", data);
      toast.success("Password changed successfully");
      window.location.replace("/login");
    },
    onError: () => {
      toast.error("Failed to request action for changing the password");
    },
  });

  function handleRequestAvailability() {
    if (
      phoneRef?.current?.value.length == 10 &&
      otpRef?.current?.value.length == 4
    ) {
      setIsRequestAvailable(true);
    } else {
      setIsRequestAvailable(false);
    }
  }

  function handleVerifyRequestAvailability() {
    if (
      otpRef?.current?.value.length !== 0 &&
      newPasswordRef?.current?.value.length !== 0
    ) {
      setIsVerifyRequestAvailable(true);
    } else {
      setIsVerifyRequestAvailable(false);
    }
  }

  otpValidTill = (otpExpireTime - Date.now()) / 1000;

  return (
    <div className="login-box">
      <a href="" className="logo flex items-center justify-center">
        <img src="/images/spaceshift_logo.png" alt="" />
      </a>
      <div className="login-box-inner flex flex-col gap-3">
        {inputMode == inputModes.phone && (
          <div className="flex flex-row items-center justify-center">
            <div className="w-full">
              <input
                className="w-full"
                placeholder="Phone"
                type="number"
                ref={phoneRef}
                onChange={(e) => {
                  setPhoneNumber(e.currentTarget.value);
                  handleRequestAvailability();
                }}
              />
              {phoneRef &&
                phoneRef?.current?.value.length !== 10 &&
                !!!phoneRef?.current?.value.match(/^-?\d+$/) && (
                  <span>Please enter a valid phone</span>
                )}
            </div>
          </div>
        )}
        {inputMode == inputModes.otp && (
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="w-full">
              <input
                className="w-full"
                placeholder="new password"
                ref={newPasswordRef}
                onChange={() => handleVerifyRequestAvailability()}
              />
              {newPasswordRef && newPasswordRef?.current?.value.length == 0 && (
                <span className="text-sm">New password is required</span>
              )}
            </div>
          </div>
        )}

        {inputMode == inputModes.phone && (
          <button
            className="blue-bt flex flex-row items-center justify-center"
            disabled={isForgotPasswordRequesting}
            onClick={() => {
              if (!phoneNumber) {
                return toast.error("please enter the phone number");
              }
              mutateForgotPasswordRequest({
                phone: phoneRef?.current?.value,
              });
            }}
          >
            {isForgotPasswordRequesting ? (
              <ClockLoader size={20} color="white" />
            ) : (
              "Verify phone"
            )}
          </button>
        )}

        {inputMode == inputModes.otp && (
          <VerifyOtp
            otpExpireMinutes={Math.floor(otpValidTill / 60)}
            otpExpireSeconds={Math.floor(otpValidTill % 60)}
            resendOtpHandler={() => {
              mutateForgotPasswordRequest({
                phone: phoneNumber,
              });
            }}
            submitHandler={(otp) => {
              if (!isVerifyRequestAvailable) {
                return toast.error("please fill all of the fields");
              }

              mutateForgotPasswordVerify({
                newPassword: newPasswordRef?.current?.value,
                otp: otp.toString(),
                phone: phoneNumber,
              });
            }}
            isLoading={isForgotPasswordVerifying}
            onTimerEnd={() => {
              Timer.setOtpExpireTime({
                ...storedTimer,
                forgotPassword: 0,
              });
              // localStorage.setItem(
              //   "otp-time-map",
              //   JSON.stringify({
              //     forgotPasswordVerify: {
              //       [phoneNumber]: "",
              //     },
              //   })
              // );
            }}
          />
        )}
      </div>

      <Toaster />
    </div>
  );
}

export { ForgotPassword };
