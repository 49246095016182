import { documentTypeMap } from "constant";

class ObjectDisplayData {
    key: string;
    value: string | ObjectDisplayData[];

    constructor(key: string, value: string | ObjectDisplayData[]) {
        this.key = key;
        this.value = value;
    }
}
function processObjectData(doc: { any: any }): ObjectDisplayData[] {


    function resolveData(obj: any): ObjectDisplayData[] {
        const result: ObjectDisplayData[] = [];
        Object.entries(obj).map((entry) => {

            const title = entry[0];
            let value: string | any = entry[1];


            if (typeof value == 'object') {
                value = resolveData(value);
            }

            const item = new ObjectDisplayData(title, value);
            result.push(item);
        })

        return result;
    }

    return (resolveData(doc));
}

function camelCaseToProperWord(camelCase: string) {
    // Add a space before capital letters and then capitalize the first letter
    return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

interface VerifiedDocumentData {
    name: string
}
function extractVerifiedDocumentData(doc: any): VerifiedDocumentData {
    const { documentType } = doc;
    const result: VerifiedDocumentData = {
        name: ""
    };
    switch (documentType) {
        case documentTypeMap.AADHAR:
            {
                const { isByOtp } = doc;
                if (isByOtp) {
                    result.name = doc.meta.name;
                    break;
                } else {
                    result.name = "";
                }
            }
            break;

        case documentTypeMap.DIGI_URL:
            result.name = "";
            break;
        case documentTypeMap.DL:
            result.name = doc.meta.name;
            break;
        case documentTypeMap.PAN:
            result.name = doc.meta.name;
            break;
        case documentTypeMap.PASSPORT:
            result.name = "";
            break;
    }

    return result
}



export { processObjectData, camelCaseToProperWord, extractVerifiedDocumentData }