import React from "react";
import "./index.css";
import "./output.css";


import App from "./App";
import "./responsive.css";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { queryClient } from "queries";

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,

  document.getElementById("root")
);
