import { DocumentDisplayCard } from "components/DocumentDisplayCard";
import {
  documentTypeMap,
  getDocumentEntries,
  queryKeys,
  userDetails,
} from "constant";
import React from "react";
import { useQuery } from "react-query";
import { ClockLoader, MoonLoader } from "react-spinners";
import { camelCaseToProperWord, processObjectData } from "utils/dataProcessing";

function DocumentCard({ documentId }) {
  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  const { data: documentData, isLoading: isDocumentDataLoading } = useQuery(
    [
      queryKeys.DOCUMENT_ENTRIES,
      {
        companyId: userData?.data?.companyId,
        entryId: documentId,
      },
    ],
    getDocumentEntries,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  const doc = documentData?.data?.Items[0];

  return (
    <>
      {isDocumentDataLoading && <ClockLoader color="white" />}
      {!isDocumentDataLoading && doc && <DocumentDisplayCard doc={doc} />}
    </>
  );
}

export default DocumentCard;
