import React from "react";
import { DataField } from ".";
import moment from "moment";

function PanCard({ doc }) {
  const { addedBy, createdAt, documentId, meta } = doc;
  const { address, dob, name, isValid } = meta;

  return (
    <div className="all-verified-documents-wrap flex p-7 mt-8 flex-col">
      <p className="adhaar-data-box flex self-start">Pan</p>
      <div className="customer-details-top">
        <DataField title="Name">{name}</DataField>
        <DataField title="Date of birth">{dob}</DataField>
        <DataField title="Document verified on">
          {moment(createdAt).format("DD/MM/YYYY")}
        </DataField>
        <DataField title="Document verified by">
          {`${addedBy.name} (${addedBy.role})`}
        </DataField>
        <DataField title="Address">{address}</DataField>
        <DataField title="Document validity status">
          {isValid.toString()}
        </DataField>
      </div>
    </div>
  );
}

export default PanCard;
