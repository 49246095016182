import { Layout } from "components";
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { allCashPaymentRequests, cashRequestApprove } from "constant";
import toast, { Toaster } from "react-hot-toast";

function CashPaymentRequests() {
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);

  const { data, refetch } = useQuery(
    [
      "cash-payment-requests",
      {
        limit: 5,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    allCashPaymentRequests
  );

  const { mutate: cashApprove, isLoading } = useMutation({
    mutationFn: cashRequestApprove,
    onSuccess: async (data) => {
      toast.success("Payment approved");
      refetch();
    },
  });

  return (
    <Layout>
      <div className="text-white">
        <div className="p-7 body-bg">
          <div className="all-verified-documents-wrap flex p-7 flex-col">
            <div>
              <div className="flex justify-between items-center mb-6">
                <h2>Gift requests</h2>
              </div>
              <div className="table-container w-full">
                <table className="w-full">
                  <thead>
                    <tr className="head-dashed-underline">
                      <th className="uppercase text-left">
                        Request for Company
                      </th>
                      <th className="uppercase">Company Admin Phone</th>
                      <th className="uppercase">Tokens</th>
                      <th className="uppercase">Reseller id</th>
                      <th className="uppercase">Approved</th>
                      <th className="uppercase"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.Items?.map((item) => {
                      return (
                        <tr>
                          <td className=""> {item?.companyMeta?.companyId}</td>
                          <td className=""> {item?.companyMeta?.adminId}</td>
                          <td className="">{item.requestedTokens}</td>
                          <td className=""> {item.resellerId}</td>
                          <td className="">
                            {" "}
                            {item.isApproved ? "Yes" : "No"}
                          </td>
                          <td className="">
                            <button
                              className={`p-2 ${
                                item.isApproved || isLoading
                                  ? "bg-neutral-500"
                                  : "bg-green-500"
                              } text-white rounded-md`}
                              onClick={() => {
                                cashApprove({ requestId: item.id });
                              }}
                              disabled={item.isApproved || isLoading}
                            >
                              Approve
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4 h">
            <div
              className="flex items-center"
              onClick={() => {
                setLastEvaluatedKey((olds: any) => {
                  const newArr = JSON.parse(JSON.stringify(olds));
                  return newArr?.length ? newArr?.slice(0, -1) : newArr;
                });
              }}
              style={{
                backgroundColor: LastEvaluatedKeys?.length
                  ? "#009ef7"
                  : "#2b2b40",
                color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
              }}
            >
              Back
            </div>
            <div
              onClick={() => {
                if (data?.data?.LastEvaluatedKey) {
                  setLastEvaluatedKey((olds) => {
                    return [...olds, data?.data?.LastEvaluatedKey];
                  });
                }
              }}
              className={`flex items-center`}
              style={{
                backgroundColor: data?.data?.LastEvaluatedKey
                  ? "#009ef7"
                  : "#2b2b40",
                color: data?.data?.LastEvaluatedKey ? "white" : "#7e8299",
              }}
            >
              Next
            </div>
          </div>
          {/* Request for: {item.requestedForUserId}, Request token:{" "}
              {item.requestedTokens}, Reseller id: {item.resellerId} , Request
              amount: {item.requestedAmount}, Approved:{" "}
              {String(item.isApproved)}{" "} */}
        </div>
      </div>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

export { CashPaymentRequests };
