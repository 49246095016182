import { documentTypeMap } from "./documentTypeMap";

const documentModeMap: any = {
  [documentTypeMap.AADHAR]: {
    mode: "basic-aadhar",
  },
  [documentTypeMap.PAN]: {
    mode: "basic-pan",
  },
  [documentTypeMap.DL]: {
    mode: "basic-dl",
  },
  [documentTypeMap.DIGI_URL]: {
    message: "Url created successfully",
  },
  [documentTypeMap.DIGI_EAADHAR]: {
    mode: "e-aadhar",
  },
  [documentTypeMap.MANUAL]: {
    mode: "manual",
  },
};

export { documentModeMap };
