import React from 'react'
import { Layout } from "components";
function SalesAccount() {
  return (
    <Layout>
    <div>index</div>
    </Layout>
  )
}

export default SalesAccount