const documentTypeMap = {
  AADHAR: "aadhar",
  PAN: "pan",
  DL: "dl",
  PASSPORT: "passport",
  MANUAL: "manual",
  DIGI_URL: "digiUrl",
  DIGI_EAADHAR: "digiEaadhar",
};

export { documentTypeMap };
