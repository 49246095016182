import { RegisterClientData } from "pages/types";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertHotelBnbsSchema } from "./utilsConvert";
import moment from "moment";
import clsx from "clsx";
import { ClockLoader } from "react-spinners";
import { extractVerifiedDocumentData } from "utils/dataProcessing";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button, Popover } from "@radix-ui/themes";
import toast from "react-hot-toast";

function ConvertCustomer({
  onConvert,
  onCancel,
  isConverting,
  doc,
}: {
  onConvert: (registrationData: RegisterClientData) => any;
  onCancel: any;
  isConverting: any;
  doc: any;
}) {
  const presentDocumentData = extractVerifiedDocumentData(doc);
  const [dateRange, setDateRange] = useState({
    startDate: Date.now(),
    endDate: Date.now() + 24 * 60 * 60 * 1000,
    key: "selection",
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(convertHotelBnbsSchema),
    defaultValues: {
      advancedPaid: 0,
      dailyCost: null,
      name: presentDocumentData.name,
      phoneNumber: "",
      roomNumber: "",
      roomType: "",
    },
  });
  const getRoomType = watch("roomType");
  const getRoomNumber = watch("roomNumber");
  const getPhoneNumber = watch("phoneNumber");
  const getName = watch("name");
  let getNoDays = moment(dateRange.endDate).diff(
    moment(dateRange.startDate).startOf("day"),
    "days"
  );
  const getDailyCost = watch("dailyCost");
  const getAdvancedPaid = watch("advancedPaid");
  const amountToPaid =
    Number(getDailyCost) * (getNoDays > 0 ? getNoDays : 1) -
    Number(getAdvancedPaid);

  const [amountExceeded, setAmountExceeded] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setAmountExceeded(false);

    if (Math.sign(amountToPaid) === -1) {
      setAmountExceeded(true);
    }
  }, [amountToPaid, getNoDays]);

  function handleConvertCustomer() {
    if (isValid && !amountExceeded && getNoDays > 0) {
      onConvert({
        advancedPaid: Number(getAdvancedPaid),
        entryDate: moment(dateRange.startDate).unix() * 1000,
        returnDate: moment(dateRange.endDate).unix() * 1000,
        dailyCost: Number(getDailyCost),
        name: getName,
        phoneNumber: getPhoneNumber,
        roomNumber: getRoomNumber,
        roomType: getRoomType,
        noOfDays: getNoDays,
      });
    } else {
      toast.error(
        "Form is not valid!"
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(handleConvertCustomer)}>
      <div className="modal-wrapper flex flex-col">
        <div className="modal-title flex p-6 w-full">Onboard your customer</div>
        <div className="modal-body flex flex-col pt-0 p-6  w-full">
          <div className="convert-clients-wrap p-8">
            <div className="grid grid-cols-1 gap-4">
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  Name:
                </label>
                <input
                  {...register("name")}
                  placeholder="Enter customer name"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.name },
                    { "is-valid": !errors && errors.name }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.name?.message}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 gap-6 mt-6">
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  Phone Number
                </label>
                <input
                  {...register("phoneNumber")}
                  placeholder="Ex: 9012345678"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.phoneNumber },
                    { "is-valid": !errors && errors.phoneNumber }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.phoneNumber?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Room Number"}
                </label>
                <input
                  {...register("roomNumber")}
                  type="text"
                  autoComplete="off"
                  placeholder={"Ex: A-409"}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.roomNumber },
                    { "is-valid": !errors && errors.roomNumber }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.roomNumber?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Room Type"}
                </label>
                <input
                  {...register("roomType")}
                  type="text"
                  autoComplete="off"
                  placeholder={"Ex: Super Dulex"}
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.roomType },
                    { "is-valid": !errors && errors.roomType }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.roomType?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Daily Cost (₹)"}
                </label>
                <input
                  {...register("dailyCost")}
                  placeholder="Ex: 500"
                  type="text"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.dailyCost },
                    { "is-valid": !errors && errors.dailyCost }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.dailyCost?.message}</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  {"Select stay period"}
                </label>
                <Popover.Root open={showPicker}>
                  <Popover.Trigger>
                    <Button
                      className="pick-date-range"
                      variant="soft"
                      onClick={() => setShowPicker(true)}
                    >
                      {moment(dateRange.startDate).format("DD-MM-YYYY")} to{" "}
                      {moment(dateRange.endDate).format("DD-MM-YYYY")}
                    </Button>
                  </Popover.Trigger>
                  <Popover.Content style={{ zIndex: 1000 }}>
                    <button
                      className="px-2 border float-right text-black"
                      onClick={() => {
                        setShowPicker(false);
                      }}
                    >
                      X
                    </button>
                    <DateRange
                      showDateDisplay={false}
                      showPreview={false}
                      showSelectionPreview={false}
                      showMonthAndYearPickers={false}
                      showMonthArrow={true}
                      ranges={[dateRange]}
                      minDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() - 1)
                        )
                      }
                      maxDate={
                        new Date(
                          new Date().setFullYear(new Date().getFullYear() + 1)
                        )
                      }
                      onChange={(data) => {
                        let { startDate, endDate } = data.selection;
                        setDateRange({
                          startDate,
                          endDate,
                          key: "selection",
                        });
                      }}
                    />
                  </Popover.Content>
                </Popover.Root>
              </div>
              <div className="flex flex-col">
                <label className="form-label fw-bolder text-dark fs-6">
                  Advanced Paid(₹)
                </label>
                <input
                  {...register("advancedPaid")}
                  placeholder="Enter advanced paid if any"
                  type="number"
                  autoComplete="off"
                  className={clsx(
                    "form-control form-control-lg form-control-solid",
                    { "is-invalid": errors && errors.advancedPaid },
                    { "is-valid": !errors && errors.advancedPaid }
                  )}
                />
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{errors.advancedPaid?.message}</span>
                    <span style={{ color: "red" }}>
                      {amountExceeded &&
                        `The advance amount exceed by Rs.${amountToPaid * -1}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div className="flex no-days flex-col">
                <div className="mb-5 mt-2">
                  <p>
                    <span className="col-lg-4 fw-bold text-muted fs-6">
                      No of Days:
                    </span>
                    &nbsp;{" "}
                    <b className="fs-7">{isNaN(getNoDays) ? 0 : getNoDays}</b>{" "}
                  </p>
                </div>
                {!amountExceeded && (
                  <div className="mb-5">
                    <p>
                      <span className="col-lg-4 fw-bold text-muted fs-6">
                        Amount to be Paid (Client):
                      </span>
                      &nbsp;{" "}
                      <b className="fs-7">
                        {isNaN(amountToPaid) ? 0 : "₹" + amountToPaid}
                      </b>{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer flex justify-end p-6 w-full gap-2">
          <button
            type="submit"
            className="blue-bt assign-bt flex items-center"
            onClick={handleConvertCustomer}
            disabled={isConverting}
          >
            {isConverting ? (
              <ClockLoader color="white" size={20} />
            ) : (
              "Register guest"
            )}
          </button>
          <button
            type="button"
            disabled={isConverting}
            className="cancel-bt flex items-center"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
}

export default ConvertCustomer;
