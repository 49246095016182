import React from "react";
import { DataField } from ".";
import moment from "moment";

function AdhaarCard({ doc }) {
  const { addedBy, createdAt, meta } = doc;
  const { ageBand, state, verified } = meta;
  console.log("this is doc", doc)
  return (
   <>
      <p className="adhaar-data-box flex self-start">Adhaar</p>
      <div className="customer-details-top">
        <DataField title="Document verified on">
          {moment(createdAt).format("DD/MM/YYYY")}
        </DataField>
        <DataField title="Document verified by">
          {`${addedBy.name} (${addedBy.role})`}
        </DataField>
        <DataField title="age band">{ageBand}</DataField>
        <DataField title="address">{state}</DataField>
        <DataField title="verification status">{verified}</DataField>
      </div>
      </>
  );
}

export default AdhaarCard;
