import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  queryKeys,
  getPublicLinkDetails,
  documentPublicEntry,
  documentTypeMap,
} from "constant";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { HashLoader } from "react-spinners";
import { FcExpired } from "react-icons/fc";
import moment from "moment";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

function EndUserPage() {
  const { linkId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data,
    isLoading: isLinkDataLoading,
    refetch,
  } = useQuery(
    [
      queryKeys.GET_ENDUSER_LINK_DETAILS,
      {
        linkId,
      },
    ],
    getPublicLinkDetails
  );

  const { mutate: mutateDocument, isLoading } = useMutation({
    mutationFn: documentPublicEntry,
    onSuccess: (data) => {
      if (
        (data?.data?.mapId === documentTypeMap.AADHAR ||
          data?.data?.mapId === documentTypeMap.DL ||
          data?.data?.mapId === documentTypeMap.PAN ||
          data?.data?.mapId === documentTypeMap.DIGI_EAADHAR) &&
        data?.data?.verified === "true"
      ) {
        toast.success("Document verification successfull");
      } else if (data?.data?.mapId === documentTypeMap.DIGI_URL) {
        localStorage.setItem("digiRequestId", data?.data?.requestId);
        window.location.replace(data?.data?.url);
      } else {
        toast.error("Third Party Server Error");
      }
      refetch();
    },
    onError: () => {
      toast.error("Third Party Server Error");
    },
  });

  useEffect(() => {
    if (searchParams.get("confirmAuthorization") == "true" && linkId) {
      mutateDocument({
        documentId: localStorage.getItem("digiRequestId"), //  here document id is requestId
        linkId: linkId,
        mapId: documentTypeMap.DIGI_EAADHAR,
        documentType: documentTypeMap.AADHAR,
      });
      localStorage.removeItem("digiRequestId");
      setSearchParams({});
      refetch();
    }
  }, [linkId]);

  const AAdharVerifyCard = ({ isCompleted }) => {
    return (
      <div className="verify-box">
        <h2>Verify with DigiLocker</h2>
        <button
          className={
            isCompleted ? "text-green-600" : "p-2 blue-bt digi-verify-bt"
          }
          onClick={() => {
            if (!isCompleted)
              mutateDocument({
                documentId: window.location.href, //  here document id is callback url
                linkId,
                documentType: documentTypeMap.AADHAR,
                mapId: documentTypeMap.DIGI_URL,
              });
          }}
        >
          {isCompleted ? "Completed" : "Verify"}
        </button>
      </div>
    );
  };

  const DLVerifyCard = ({ isCompleted }) => {
    const drivingLicenseSchema = Yup.object().shape({
      drivingLicense: Yup.string().matches(
        /^(([A-Za-z]{2}[0-9]{2})|([A-Za-z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
        { message: "Please enter valid number.", excludeEmptyString: false }
      ),
      dob: Yup.string().required("DOB is required"),
    });
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
      resetField,
    } = useForm({
      resolver: yupResolver(drivingLicenseSchema),
    });

    const handleSub = (values) => {
      mutateDocument({
        documentId: values.drivingLicense.toUpperCase(),
        linkId,
        documentType: documentTypeMap.DL,
        mapId: documentTypeMap.DL,
        dob: moment(values.dob).format("DD/MM/YYYY"),
      });
    };

    return (
      <form onSubmit={handleSubmit(handleSub)}>
        <div className="verify-box">
          <h2>Verify with DL number</h2>

          <div className="adhaar-no-box">
            <h3>Enter Driving License Number</h3>

            <div className="adhaar-putin-box">
              <input
                {...register("drivingLicense")}
                placeholder="Ex: WB2520201234567"
                className={clsx(
                  "border-2 w-full",
                  { "is-invalid": errors && errors.drivingLicense },
                  { "is-valid": !errors && errors.drivingLicense }
                )}
                type="text"
                autoComplete="off"
              />

              <p className="mt-1 mb-2">
                Enter a valid number for Driving License verification.
              </p>
              <div>
                <span role="alert">{errors.drivingLicense?.message}</span>
              </div>
            </div>

            {!isCompleted && <h3>Choose DOB</h3>}
            <div className="adhaar-putin-box">
              {!isCompleted && (
                <>
                  <input
                    {...register("dob")}
                    max={moment().format("YYYY-MM-DD")}
                    className={clsx(
                      "border-2 w-full",
                      { "is-invalid": errors && errors.dob },
                      { "is-valid": !errors && errors.dob }
                    )}
                    type="date"
                    autoComplete="off"
                  />
                  <div>
                    <span role="alert">{errors.dob?.message}</span>
                  </div>
                </>
              )}

              <button
                className={isCompleted ? "text-green-600" : "blue-bt mt-5"}
              >
                {isCompleted ? "Completed" : "Verify"}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  const PanVerifyCard = ({ isCompleted }) => {
    const panSchema = Yup.object().shape({
      pan: Yup.string().matches(/[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      }),
    });
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm({
      resolver: yupResolver(panSchema),
    });

    const handleSub = (values) => {
      mutateDocument({
        documentId: values.pan.toUpperCase(),
        linkId,
        documentType: documentTypeMap.PAN,
        mapId: documentTypeMap.PAN,
      });
    };

    return (
      <form onSubmit={handleSubmit(handleSub)}>
        <div className="veriication-detail-wrapper flex flex-col">
          <div className="verify-box">
            <h2>Verify with PAN number</h2>

            <div className="adhaar-no-box">
              {!isCompleted && <h3>Enter PAN Number</h3>}
              <div className="adhaar-putin-box">
                {!isCompleted && (
                  <input
                    className={clsx(
                      "border-2 w-full uppercase",
                      { "is-invalid": errors && errors.pan },
                      { "is-valid": !errors && errors.pan }
                    )}
                    type="text"
                    autoComplete="off"
                    {...register("pan")}
                    placeholder="Ex: ABC23Q567P"
                  />
                )}
                {!isCompleted && (
                  <>
                    <p className="mt-1 mb-2">
                      Enter 10 digit number for pan verification.
                    </p>
                    <div className="fv-help-block">
                      <span role="alert">{errors.pan?.message}</span>
                    </div>
                  </>
                )}
                <button
                  className={isCompleted ? "text-green-600" : "blue-bt mt-5"}
                >
                  {isCompleted ? "Completed" : "Verify"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return isLinkDataLoading || isLoading ? (
    <div className="flex h-[90vh] w-full items-center justify-center">
      <HashLoader color="#36d7b7" size="100" />
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </div>
  ) : data?.data?.isExpired ? (
    <div className="flex h-[90vh] w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <FcExpired size="100" />
        <div className="font-bold text-4xl">Link Expired</div>
      </div>
    </div>
  ) : (
    <div className="h-full w-full">
      <div className="h-[100vh] w-44 left-panel">
        <div className="left-logo">
          <a href="" className="flex items-center justify-center">
            <img src="/images/black-icon-logo.png" alt="" />
          </a>
        </div>

        <div className="left-menu"></div>
      </div>

      <div className="w-full body-container h-full p-20 xs:p-0 ver-pan">
        <div className="my-4 border text-white w-fit p-4 xs:p-0 dvr-box">
          Document verification requested by{" "}
          <span className=" text-teal-400 text-xl">
            {data?.data?.companyDetails?.name}
          </span>{" "}
          <br />
          Address:{" "}
          <span className=" text-neutral-200">
            {data?.data?.companyDetails?.address}
          </span>
          , State:{" "}
          <span className=" text-neutral-200">
            {data?.data?.companyDetails?.state}
          </span>
          , Zip:{" "}
          <span className=" text-neutral-200">
            {data?.data?.companyDetails?.zip}
          </span>
        </div>
        {data?.data?.Item?.documentTypes &&
          Object.keys(data?.data?.Item?.documentTypes).map((item) => (
            <div className="max-w-xl mb-16">
              {item === documentTypeMap.AADHAR && (
                <AAdharVerifyCard
                  isCompleted={
                    data?.data?.Item?.documentTypes[item].status === "verified"
                  }
                />
              )}
              {item === documentTypeMap.DL && (
                <DLVerifyCard
                  isCompleted={
                    data?.data?.Item?.documentTypes[item].status === "verified"
                  }
                />
              )}
              {item === documentTypeMap.PAN && (
                <PanVerifyCard
                  isCompleted={
                    data?.data?.Item?.documentTypes[item].status === "verified"
                  }
                />
              )}
            </div>
          ))}
      </div>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </div>
  );
}

export { EndUserPage };
