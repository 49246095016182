import { useState } from "react";
import { Company } from "pages/types";
import { ProfileDetail } from "./ProfileDetails";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { updateCompany, userDetails, queryKeys, userRoles } from "constant";

function CompanyDetails({
  profileData,
  refetch,
}: {
  profileData: Company;
  refetch?: any;
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const editables = {
    name: profileData?.name || "",
    state: profileData?.state || "",
    city: profileData?.city || "",
    zip: profileData?.zip || "",
    address: profileData?.address || "",
  };

  const { data: userData }: any = useQuery(
    [queryKeys.USER_DETAILS],
    userDetails
  );

  const [editableValues, setEditableValue] = useState(editables);
  const { mutate: updateCompanyMutate, isLoading: isCompanyUpdating } =
    useMutation({
      mutationFn: updateCompany,
      onSuccess: (data) => {
        if (data?.code != 200) {
          toast.error(data?.message);
        } else {
          refetch();
          setIsEditMode(false);
          setEditableValue(editables);
          toast.success("Company updated successfully");
        }
      },
    });

  return (
    <div className="">
      <div className="all-verified-documents-wrap mt-8 flex flex-col p-7">
        {/* Header */}
        <div className="header-info flex items-center justify-between">
          <h4>Company Details</h4>
          {isEditMode ? (
            <div className="flex gap-3">
              <button
                onClick={() => {
                  let isContinue = true;
                  Object.keys(editableValues).forEach((key) => {
                    if (editableValues[key]?.length < 1) {
                      toast.error(`${key} field cannot be empty`);
                      isContinue = false;
                    }
                  });
                  if (isContinue) {
                    updateCompanyMutate({
                      ...editableValues,
                      companyId: profileData?.companyId,
                    });
                  }
                }}
                disabled={isCompanyUpdating}
              >
                {isCompanyUpdating ? "Saving..." : "Save"}
              </button>{" "}
              <button
                onClick={() => {
                  setIsEditMode(false);
                  setEditableValue(editables);
                }}
              >
                Cancel
              </button>
            </div>
          ) : userData?.data?.role === userRoles.ADMIN ? (
            <button
              onClick={() => {
                setIsEditMode(true);
                setEditableValue(editables);
              }}
            >
              Edit
            </button>
          ) : (
            <div></div>
          )}
        </div>

        {isEditMode ? (
          <div className="edit-profile-section">
            <label>
             <span> Company Name</span>
              <input
                className="bg-transparent m-2"
                // style={{ border: "1px solid white" }}
                value={editableValues.name}
                onChange={(e) => {
                  setEditableValue((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
            </label>
          </div>
        ) : (
          <ProfileDetail title="Company Name" content={profileData?.name} />
        )}
        {isEditMode ? (
          <div className="edit-profile-section">
            <label>
           <span>State</span>   
              <select
                className="bg-transparent m-2"
                value={editableValues.state}
                onChange={(e) => {
                  setEditableValue((prev) => ({
                    ...prev,
                    state: e.target.value,
                  }));
                }}
              >
                <option value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra and Nagar Haveli">
                  Dadra and Nagar Haveli
                </option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </label>
          </div>
        ) : (
          <ProfileDetail title="State" content={`${profileData?.state}`} />
        )}
        {isEditMode ? (
          <div className="edit-profile-section">
            <label>
            <span>City</span>  
              <input
                className="bg-transparent m-2"
                // style={{ border: "1px solid white" }}
                value={editableValues.city}
                onChange={(e) => {
                  setEditableValue((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }));
                }}
              />
            </label>
          </div>
        ) : (
          <ProfileDetail title="City" content={`${profileData?.city || ""}`} />
        )}
        {isEditMode ? (
          <div className="edit-profile-section">
            <label>
           <span>Zip</span>   
              <input
                className="bg-transparent m-2"
                // style={{ border: "1px solid white" }}
                value={editableValues.zip}
                onChange={(e) => {
                  setEditableValue((prev) => ({
                    ...prev,
                    zip: e.target.value,
                  }));
                }}
              />
            </label> 
          </div>
        ) : (
          <ProfileDetail title="Zip" content={`${profileData?.zip || ""}`} />
        )}
        {isEditMode ? (
          <div className="edit-profile-section">
            <label>
            <span>Address</span>  
              <input
                className="bg-transparent m-2"
                // style={{ border: "1px solid white" }}
                value={editableValues.address}
                onChange={(e) => {
                  setEditableValue((prev) => ({
                    ...prev,
                    address: e.target.value,
                  }));
                }}
              />
            </label>
          </div>
        ) : (
          <ProfileDetail title="Address" content={profileData?.address} />
        )}

        <ProfileDetail
          title="Joined On"
          content={moment(profileData?.createdAt).format("DD/MM/YYYY")}
        />
        <ProfileDetail title="Company ID" content={profileData?.companyId} />
        <ProfileDetail title="Service Type" content={profileData?.type} />
        <ProfileDetail title="Contact Phone" content={profileData?.adminId} />
      </div>
      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </div>
  );
}

export default CompanyDetails;
