import { deleteCustomer, getCustomers, queryKeys, userDetails } from "constant";
import { CustomerData, DateRangeUnix } from "pages/types";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { dateTypes } from ".";
import toast, { Toaster } from "react-hot-toast";
import { DatePicker } from "components";
import moment from "moment";
import { ClockLoader } from "react-spinners";
import ConfirmationModal from "components/ConfirmationModal";
import { ImFileEmpty } from "react-icons/im";

function ActiveCustomers({
  onCustomerDelete,
  ...props
}: {
  onCustomerDelete: () => any;
}) {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [filterOption, setFilterOption] = useState("");
  const [dateRange, setDateRange] = useState<DateRangeUnix>({
    startDate: 0,
    endDate: 0,
  });
  const [modalPayload, setModalPayload] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);
  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  const {
    data: customersData,
    refetch: customersDataRefetch,
    isLoading: isCustomersDataLoading,
  } = useQuery(
    [
      queryKeys.GET_CUSTOMERS_WITH_FILTERS,
      {
        isActive: "true",
        companyId: userData?.data?.companyId,
        searchValue: phoneNumber,
        entryStartDate:
          dateRange.startDate !== 0 && filterOption == dateTypes.entryDate
            ? (dateRange.startDate * 1000).toString()
            : "",
        entryEndDate:
          dateRange.endDate !== 0 && filterOption == dateTypes.entryDate
            ? (dateRange.endDate * 1000).toString()
            : "",
        createdStartDate:
          dateRange.startDate !== 0 && filterOption == dateTypes.createdDate
            ? (dateRange.startDate * 1000).toString()
            : "",
        createdEndDate:
          dateRange.endDate !== 0 && filterOption == dateTypes.createdDate
            ? (dateRange.endDate * 1000).toString()
            : "",
        returnStartDate:
          dateRange.startDate !== 0 && filterOption == dateTypes.returnedDate
            ? (dateRange.startDate * 1000).toString()
            : "",
        returnEndDate:
          dateRange.endDate !== 0 && filterOption == dateTypes.returnedDate
            ? (dateRange.endDate * 1000).toString()
            : "",
        limit: 5,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    getCustomers,
    { enabled: !!userData?.data?.userId?.length }
  );

  const { mutate: mutateRemoveCustomer, isLoading: isCustomerRemoving } =
    useMutation({
      mutationFn: deleteCustomer,
      onSuccess: (data) => {
        if (data.code != 200) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
        }

        customersDataRefetch();
        onCustomerDelete();
      },
      onError: () => {
        toast.error("Failed to remove customer.");
      },
    });

  function handleCustomerDelete(customer: CustomerData) {
    console.log(customer.meta.name);
    setModalPayload(customer);
    setConfirmationModal(true);
  }

  function handleSearch() {
    setPhoneNumber(searchValue);
  }

  function handleResetSearch() {
    setSearchValue("");
    setPhoneNumber("");
  }

  useEffect(() => {
    customersDataRefetch();
  }, []);

  return (
    <>
      {/* customer list table */}
      <div className="all-verified-documents-wrap flex p-7 md:mt-8 xl:mt-8  flex-col">
        <div className="flex justify-between items-center mb-6">
          {/* Heading */}
          <h2>Customers List </h2>
        </div>
        {/* Filter area */}
        <div className="filter-box flex p-0 justify-between md:p-7 xl:p-7 flex-col md:flex-row xl:flex-row md:items-end">
          {/* Dropdown for date type */}
          <div className="flex flex-col">
            <div className="filter-title">
              <h2>Filter by Date type:</h2>
            </div>

            <div className="choose-doc-type flex items-center mt-2 gap-3">
              <select
                id="dataType"
                name="dataType"
                className="mt-1 p-2 block w-48 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                onChange={(e) => setFilterOption(e.target.value)}
                value={filterOption}
              >
                {/* Add your data type options here */}
                <option value="">choose date type</option>
                <option value={dateTypes.createdDate}>created date</option>
                <option value={dateTypes.returnedDate}>return date</option>
                <option value={dateTypes.entryDate}>checkin date</option>
              </select>
            </div>
          </div>

          {filterOption !== "" && (
            <div className="flex flex-col">
              <DatePicker onChange={(dateRange) => setDateRange(dateRange)} />
            </div>
          )}

          {/* Text field for phone search */}
          <div className="flex flex-col filter-date">
            <h2 className="mb-2"> Search by phone:</h2>
            <div className="flex flex-col md:flex-row xl:flex-row gap-2 items-center">
              <div className="search-input-box">
                <i className="bi bi-search"></i>
                <input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  id="searchByPhone"
                  name="searchByPhone"
                  className="mt-1 p-2 block w-48 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <button className="serach-bt" onClick={handleSearch}>
                <i className="bi bi-search"></i>
              </button>
              <button
                className="refresh-bt"
                onClick={() => handleResetSearch()}
              >
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Table */}
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            className="table-container w-full"
            style={{ height: "40vh", overflow: "auto" }}
          >
            <table className="w-full">
              {/* Table Head */}
              <thead>
                <tr className="head-dashed-underline">
                  <th className="uppercase">Name</th>
                  <th className="uppercase">Phone</th>
                  <th className="uppercase">created at</th>
                  <th className="uppercase">room number</th>
                  <th className="uppercase">checkin date</th>
                  <th className="uppercase">checkout date</th>
                  <th className="uppercase">status</th>
                  <th className="uppercase">created by</th>
                  <th className="uppercase">documents</th>
                  <th className="uppercase">actions</th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody>
                {customersData?.data?.Items?.map((customer, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {customer.meta.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {customer.phone}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {moment(customer.createdAt).format("Do MMM YYYY")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {customer.meta.roomNumber}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {moment(customer?.entryDate).format("Do MMM YYYY")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {moment(customer.returnDate).format("Do MMM YYYY")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {customer.isActive &&
                        customer.returnDate >
                          (customersData?.data?.currentTime || Date.now()) ? (
                          <div className="p-2 bg-green-700 text-white rounded-lg w-max h-max">
                            Active
                          </div>
                        ) : (
                          <div className="p-2 bg-red-800 text-white rounded-lg w-max h-max">
                            Inactive
                          </div>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {customer.addedBy.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {customer.documentEntryIds.length}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          className=""
                          style={{
                            gap: "0.2rem",
                          }}
                        >
                          <button
                            className="view flex items-center justify-center"
                            style={{ marginInline: 0, marginBottom: 6 }}
                            onClick={() =>
                              navigate("/customer-details", {
                                state: customer,
                              })
                            }
                          >
                            view
                          </button>
                          <button
                            onClick={() => handleCustomerDelete(customer)}
                            className="delete-bt flex items-center justify-center"
                            style={{ marginInline: 0 }}
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {customersData?.data?.Items?.length == 0 && (
                  <tr>
                    <td colSpan={10}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isCustomersDataLoading && (
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
        <div
          className="flex items-center"
          onClick={() => {
            setLastEvaluatedKey((olds: any) => {
              const newArr = JSON.parse(JSON.stringify(olds));
              return newArr?.length ? newArr?.slice(0, -1) : newArr;
            });
          }}
          style={{
            backgroundColor: LastEvaluatedKeys?.length ? "#009ef7" : "#2b2b40",
            color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
          }}
        >
          Back
        </div>
        <div
          onClick={() => {
            if (customersData?.data?.LastEvaluatedKey) {
              setLastEvaluatedKey((olds) => {
                return [...olds, customersData?.data?.LastEvaluatedKey];
              });
            }
          }}
          className={`flex items-center`}
          style={{
            backgroundColor: customersData?.data?.LastEvaluatedKey
              ? "#009ef7"
              : "#2b2b40",
            color: customersData?.data?.LastEvaluatedKey ? "white" : "#7e8299",
          }}
        >
          Next
        </div>
      </div>

      <ConfirmationModal
        payload={modalPayload}
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        message={"Are you sure ?"}
        onNo={() => setConfirmationModal(false)}
        isLoading={isCustomerRemoving}
        onYes={(payload) => {
          console.log(payload);

          const removeCustomerArgs: any = {
            companyId: payload.companyId,
            customerId: payload.customerId,
          };

          mutateRemoveCustomer({ queryKey: ["", removeCustomerArgs] });

          setConfirmationModal(false);
        }}
      />

      <Toaster
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </>
  );
}

export default ActiveCustomers;
