import { DataField } from ".";
import moment from "moment";
import { useQuery } from "react-query";
import { getPresignedUrl, queryKeys } from "constant";
import { MoonLoader } from "react-spinners";

function ManualCard({ doc }) {
  const {
    addedBy,
    createdAt,
    documentType,
    documentS3Key,
    documentId,
    companyId,
  } = doc;

  const { data, isLoading } = useQuery(
    [queryKeys.GET_MANUAL_DOCUMENT_URL, { documentS3Key }],
    () =>
      getPresignedUrl({
        companyId,
        getFileKey: documentS3Key,
        bucketNameKey: "DOCUMENTS_BUCKET",
      })
  );

  return (
    <div className="all-verified-documents-wrap flex p-7 flex-col">
      <p className="adhaar-data-box flex self-start">Manual</p>
      <div className="customer-details-top">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "50%",
            height: "50%",
            marginBottom:"20px"
          }}
        >
          {isLoading ? (
            <MoonLoader color="rgba(40, 45, 120, 1)" size="20" />
          ) : (
            <img src={data?.data?.url || ""} />
          )}
        </div>
        <DataField title="Document verified on">
          {moment(createdAt).format("DD/MM/YYYY")}
        </DataField>
        <DataField title="Document verified by">
          {`${addedBy.name} (${addedBy.role})`}
        </DataField>
        <DataField title="Document Id">********{documentId}</DataField>
        <DataField title="Document Type">{documentType}</DataField>
        <DataField title="Status">Verified</DataField>
      </div>
    </div>
  );
}

export { ManualCard };
