import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60, // 1 minute

      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      notifyOnChangePropsExclusions: ["isStale"],
    },
  },
});

export { queryClient };
