import { Button } from "@radix-ui/themes";
import {
  getNextInvoiceSerial,
  getSkipInvoiceSerial,
  queryKeys,
} from "constant";
import moment from "moment";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

function NextInvoiceSerial() {
  const {
    data: nextInvoiceSerialData,
    isLoading: isNextInvoiceSerialDataLoading,
    refetch: refetchNextInvoiceSerialData,
  }: any = useQuery([queryKeys.GET_NEXT_INVOICE_SERIAL], getNextInvoiceSerial);
  console.log("next invoice serial data", nextInvoiceSerialData);

  const {
    mutate: skipInvoiceSerialMutate,
    isLoading: isSkipInvoiceSerialDataLoading,
  } = useMutation({
    mutationFn: getSkipInvoiceSerial,
    onSuccess: (data) => {
      if (data.code != 200) {
        toast.error(data.message);
      }
    },
    onError: () => {
      toast.error("Unable to skip invoice serial");
    },
  });

  return (
    <div style={{ color: "white" }} className="flex flex-col ">
      <div
        style={{
          width: "100%",
          padding: "8px",
        }}
      >
        <div className="table-container w-full p-4">
          <table className="w-full">
            {/* Table Head */}
            <thead>
              <tr className="head-dashed-underline">
                {/* Add your column headers here */}
                <th className="uppercase">ID</th>
                <th className="uppercase">Invoice serial </th>
                <th className="uppercase">Last issue date</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {nextInvoiceSerialData?.data?.Items.map(
                (
                  item: {
                    id: string;
                    lastIssueDate: string;
                    invoiceSerial: number;
                  },
                  index
                ) => {
                  return (
                    <tr key={index}>
                      {/* Add your row data here */}
                      <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item.invoiceSerial}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {moment(item.lastIssueDate).format("Do MMM YYYY")}
                      </td>
                    </tr>
                  );
                }
              )}
              {nextInvoiceSerialData?.data?.Items?.length == 0 && (
                <tr>
                  <td colSpan={7}>
                    <div className="justify-center items-center flex-col flex">
                      <ImFileEmpty className="text-5xl mb-4" />
                      <div className="font-bold text-xl">No records found</div>
                    </div>
                  </td>
                </tr>
              )}
              {isNextInvoiceSerialDataLoading && (
                <tr>
                  <td colSpan={7}>
                    <div
                      style={{
                        width: "100%",
                        height: "300px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ClockLoader color="white" size="20" />{" "}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="skip-invoice-serial-wrap pt-3">
            <button
              className="assign-bt flex items-center"
              onClick={() => {
                skipInvoiceSerialMutate();
                refetchNextInvoiceSerialData();
              }}
              disabled={isSkipInvoiceSerialDataLoading}
            >
              {isSkipInvoiceSerialDataLoading
                ? "Loading..."
                : "Skip invoice serial"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NextInvoiceSerial;
