import { getCustomers, queryKeys, userDetails } from "constant";
import { DateRangeUnix } from "pages/types";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { dateTypes } from ".";
import { DatePicker } from "components";
import moment from "moment";
import { ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

function InActiveCustomers({
  refreshCustomers,
  ...props
}: {
  refreshCustomers: boolean;
}) {
  const [filterOption, setFilterOption] = useState("");
  const [dateRange, setDateRange] = useState<DateRangeUnix>({
    startDate: 0,
    endDate: 0,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);

  const { data: userData } = useQuery(
    [queryKeys.USER_DETAILS, { path: window.location.pathname }],
    userDetails
  );
  const {
    data: customersData,
    refetch: customersDataRefetch,
    isLoading: isCustomersDataLoading,
  } = useQuery(
    [
      queryKeys.GET_CUSTOMERS_INACTIVE,
      {
        isActive: "false",
        companyId: userData?.data?.companyId,
        searchValue: phoneNumber,
        entryStartDate:
          dateRange.startDate !== 0 && filterOption == dateTypes.entryDate
            ? (dateRange.startDate * 1000).toString()
            : "",
        entryEndDate:
          dateRange.endDate !== 0 && filterOption == dateTypes.entryDate
            ? (dateRange.endDate * 1000).toString()
            : "",
        createdStartDate:
          dateRange.startDate !== 0 && filterOption == dateTypes.createdDate
            ? (dateRange.startDate * 1000).toString()
            : "",
        createdEndDate:
          dateRange.endDate !== 0 && filterOption == dateTypes.createdDate
            ? (dateRange.endDate * 1000).toString()
            : "",
        returnStartDate:
          dateRange.startDate !== 0 && filterOption == dateTypes.returnedDate
            ? (dateRange.startDate * 1000).toString()
            : "",
        returnEndDate:
          dateRange.endDate !== 0 && filterOption == dateTypes.returnedDate
            ? (dateRange.endDate * 1000).toString()
            : "",
        limit: 5,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    getCustomers,
    {
      enabled: !!userData?.data?.userId?.length,
    }
  );

  console.log("inactive customers", customersData);

  function handleSearch() {
    setPhoneNumber(searchValue);
  }

  function handleResetSearch() {
    setSearchValue("");
    setPhoneNumber("");
  }

  useEffect(() => {
    customersDataRefetch();
  }, [refreshCustomers]);

  return (
    <>
      <div className="all-verified-documents-wrap flex p-7 mt-8 md:mt-8 xl:mt-8  flex-col">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-red-400"> Deleted customers List</h2>
        </div>

        <div className="filter-box flex justify-between p-0 md:p-7 xl:p-7 flex-col md:flex-row xl:flex-row">
          <div className="flex flex-col">
            <div className="filter-title">
              <h2>Filter by Date type:</h2>
            </div>

            <div className="choose-doc-type flex items-center mt-2 gap-3">
              <select
                id="dataType"
                name="dataType"
                className="mt-1 p-2 block w-48 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                onChange={(e) => setFilterOption(e.target.value)}
                value={filterOption}
              >
                <option value="">choose date type</option>
                <option value={dateTypes.createdDate}>created date</option>
                <option value={dateTypes.returnedDate}>return date</option>
              </select>
            </div>
          </div>

          {filterOption !== "" && (
            <div className="flex flex-col">
              <DatePicker onChange={(dateRange) => setDateRange(dateRange)} />
            </div>
          )}

          <div className="flex flex-col filter-date">
            <h2 className="mb-2"> Search by phone:</h2>
            <div className="flex items-center flex-col md:flex-row xl:flex-row gap-2">
              <div className="search-input-box">
                <i className="bi bi-search"></i>
                <input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  type="text"
                  id="searchByPhone"
                  name="searchByPhone"
                  className="mt-1 p-2 block w-48 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>

              <button className="serach-bt" onClick={handleSearch}>
                <i className="bi bi-search"></i>
              </button>
              <button
                className="refresh-bt"
                onClick={() => handleResetSearch()}
              >
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          className="table-container w-full"
          style={{ height: "40vh", overflow: "auto" }}
        >
          <table className="w-full">
            <thead>
              <tr className="head-dashed-underline">
                <th className="uppercase">Name</th>
                <th className="uppercase">Phone</th>
                <th className="uppercase">deleted on</th>
                <th className="uppercase">deleted by</th>
              </tr>
            </thead>

            <tbody>
              {customersData?.data?.Items?.map((customer, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {customer?.meta?.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {customer?.phone}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {moment(customer?.deletedOn).format("Do MMM YYYY")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {customer?.deletedBy?.name}
                  </td>
                </tr>
              ))}
              {customersData?.data?.Items?.length == 0 && (
                <tr>
                  <td colSpan={7}>
                    <div className="justify-center items-center flex-col flex">
                      <ImFileEmpty className="text-5xl mb-4" />
                      <div className=" font-bold text-xl">No records found</div>
                    </div>
                  </td>
                </tr>
              )}
              {isCustomersDataLoading && (
                <tr>
                  <td colSpan={7}>
                    <div
                      style={{
                        width: "100%",
                        height: "300px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ClockLoader color="white" size="20" />{" "}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
        <div
          className="flex items-center"
          onClick={() => {
            setLastEvaluatedKey((olds: any) => {
              const newArr = JSON.parse(JSON.stringify(olds));
              return newArr?.length ? newArr?.slice(0, -1) : newArr;
            });
          }}
          style={{
            backgroundColor: LastEvaluatedKeys?.length ? "#009ef7" : "#2b2b40",
            color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
          }}
        >
          Back
        </div>
        <div
          onClick={() => {
            if (customersData?.data?.LastEvaluatedKey) {
              setLastEvaluatedKey((olds) => {
                return [...olds, customersData?.data?.LastEvaluatedKey];
              });
            }
          }}
          className={`flex items-center`}
          style={{
            backgroundColor: customersData?.data?.LastEvaluatedKey
              ? "#009ef7"
              : "#2b2b40",
            color: customersData?.data?.LastEvaluatedKey ? "white" : "#7e8299",
          }}
        >
          Next
        </div>
      </div>
    </>
  );
}

export default InActiveCustomers;
