import React from "react";
import Modal from "react-responsive-modal";

function ConfirmationModal({
  open,
  onClose,
  message,
  onYes,
  onNo,
  payload,
  isLoading
}:any) {
  return (
    <Modal open={open} onClose={onClose}
    classNames={{
      overlay: 'customOverlay',
      modal: 'deleteModal',
    }}
    >
       <div className="modal-wrapper flex flex-col">
       <div className="modal-body flex flex-col p-6  w-full">
        <span className="font-bold text-white pb-5">{message}</span>
        <div className="modal-footer flex justify-end p-6 pb-0 w-full gap-3">
          <button className="blue-bt" onClick={() => onYes(payload)} disabled={isLoading}>Yes</button>
          <button className="cancel-bt flex items-center" onClick={onNo}>No</button>
        </div>
      </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
