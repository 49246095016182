import { env } from "./envConfig";
import {
  doProtectedPost,
  doProtectedGet,
  doOpenPost,
  doOpenGet,
} from "./middleWares";
import { cookie } from "constant";

export const apiUrl = `https://${env}-api.kyctracker.in`;

const s3PutDocument = async ({ presignedUrl, file, type }) => {
  return await fetch(`${presignedUrl}`, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": type,
    },
  });
};

const userDetails = async () => {
  const authToken = cookie.get("accessToken");
  let result;

  try {
    const response = await fetch(`${apiUrl}/private-user/details`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    result = response.json();
  } catch (error) {
    console.log("There was an error", error);
  }

  return result;
};

const s3GetDocument = ({ presignedUrl }) => doOpenGet(`${presignedUrl}`);
const createUser = ({ queryKey }) =>
  doOpenPost(`${apiUrl}/user/signup`, queryKey[1]);

const loginUser = ({ queryKey }) =>
  doOpenPost(`${apiUrl}/user/login`, queryKey[1]);

const getPublicLinkDetails = ({ queryKey }) =>
  doOpenPost(
    `${apiUrl}/document-entries-public/self-verify-link-details`,
    queryKey[1]
  );

const forgotPasswordRequest = (body) =>
  doOpenPost(`${apiUrl}/user/forgot-password-request`, body);

const forgotPasswordVerify = (body) =>
  doOpenPost(`${apiUrl}/user/forgot-password-verify`, body);

const documentPublicEntry = (body) =>
  doOpenPost(`${apiUrl}/document-entries-public/create`, body);

// **************************  PROTECTED APIS **************************

const getSkipInvoiceSerial = () =>
  doProtectedPost(`${apiUrl}/super-admin/skip-invoice-serial`);

const allCashPaymentRequests = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/cash-payment-requests`, queryKey[1]);

const createCompany = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/create`, queryKey[1]);

const getCompany = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/get`, queryKey[1]);

const getDocumentEntries = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/document-entries/get-all`, queryKey[1]);

const getDocumentsCount = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/document-entries/counts`, queryKey[1]);

const deleteCustomer = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/customer/delete`, queryKey[1]);

const getStaffs = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/staffs/get`, queryKey[1]);

const createStaff = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/staff/create`, queryKey[1]);

const removeStaff = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/staff/remove`, queryKey[1]);

const generateVerificationLink = ({ queryKey }) =>
  doProtectedPost(
    `${apiUrl}/document-entries/create-self-verify-link`,
    queryKey[1]
  );

const getVerificationLink = ({ queryKey }) =>
  doProtectedPost(
    `${apiUrl}/document-entries/get-verify-links-data`,
    queryKey[1]
  );

const disableVerificationLink = ({ queryKey }) =>
  doProtectedPost(
    `${apiUrl}/document-entries/disable-self-verify-link`,
    queryKey[1]
  );

const getCustomers = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/company/customer/all`, queryKey[1]);

const getWalletTransactions = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/wallet/transactions/all`, queryKey[1]);

const getWalletDetails = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/wallet/details`, queryKey[1]);

const getCoupons = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/reseller/available-coupons`, queryKey[1]);

const getResellerCoupon = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/reseller/get-reseller-coupons`, queryKey[1]);

const getResellerSales = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/reseller/get-sales`, queryKey[1]);

const getResellerStats = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/reseller/get-stats`, queryKey[1]);

const getResellers = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/get-resellers`, queryKey[1]);

const resellerCoupons = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/reseller-coupons`, queryKey[1]);

const resellerStats = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/reseller-stats`, queryKey[1]);

const getAllTransactions = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/all-transactions`, queryKey[1]);

const getNextInvoiceSerial = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/get-next-invoice-serial`, queryKey[1]);

const getSuperadminSalesStats = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/get-sales-stats`, queryKey[1]);

const getAllCompanies = ({ queryKey }) =>
  doProtectedPost(`${apiUrl}/super-admin/all-companies`, queryKey[1]);

const verifyUser = (body) =>
  doProtectedPost(`${apiUrl}/otp-verify/signup`, body);

const emailVerifyRequest = (body) =>
  doProtectedPost(`${apiUrl}/private-user/email-verify-request`, body);

const emailOtpVerify = (body) =>
  doProtectedPost(`${apiUrl}/otp-verify/email`, body);

const resetPassword = (body) =>
  doProtectedPost(`${apiUrl}/private-user/reset-password`, body);

const updateCompany = (body) =>
  doProtectedPost(`${apiUrl}/company/update`, body);

const documentEntry = (body) =>
  doProtectedPost(`${apiUrl}/document-entries/entry`, body);

const manualDocumentEntry = (body) =>
  doProtectedPost(`${apiUrl}/document-entries/entry/manual`, body);

const updateCustomer = (body) =>
  doProtectedPost(`${apiUrl}/company/customer/update`, body);

const cashRequestApprove = (body) =>
  doProtectedPost(`${apiUrl}/super-admin/approve-cash-payment`, body);

const createReseller = (body) =>
  doProtectedPost(`${apiUrl}/super-admin/create-reseller`, body);

const createCouponPrefix = (body) =>
  doProtectedPost(`${apiUrl}/super-admin/create-coupon-prefix`, body);

const deleteCompany = (body) =>
  doProtectedPost(`${apiUrl}/super-admin/delete-company`, body);

const createCoupon = (body) =>
  doProtectedPost(`${apiUrl}/reseller/generate-unique-coupon`, body);

const requestCashPayment = (body) =>
  doProtectedPost(`${apiUrl}/reseller/request-cash-payment`, body);

const createOrder = (body) =>
  doProtectedPost(`${apiUrl}/wallet/recharge/order`, body);

const doWalletRecharge = (body) =>
  doProtectedPost(`${apiUrl}/wallet/recharge/order`, body);

const getPresignedUrl = (body) =>
  doProtectedPost(`${apiUrl}/presigned-url/get`, body);

const convertToCustomer = (body) =>
  doProtectedPost(`${apiUrl}/company/customer/convert`, body);

const linkToCustomer = (body) =>
  doProtectedPost(`${apiUrl}/company/customer/link`, body);

export {
  deleteCompany,
  createCouponPrefix,
  getWalletDetails,
  doWalletRecharge,
  getWalletTransactions,
  createUser,
  loginUser,
  verifyUser,
  userDetails,
  createCompany,
  documentEntry,
  manualDocumentEntry,
  getDocumentEntries,
  getCustomers,
  getPresignedUrl,
  s3PutDocument,
  s3GetDocument,
  convertToCustomer,
  linkToCustomer,
  deleteCustomer,
  getStaffs,
  createStaff,
  removeStaff,
  generateVerificationLink,
  getVerificationLink,
  disableVerificationLink,
  getCompany,
  updateCompany,
  createOrder,
  updateCustomer,
  getCoupons,
  createCoupon,
  getResellerSales,
  getPublicLinkDetails,
  documentPublicEntry,
  getResellerCoupon,
  forgotPasswordRequest,
  forgotPasswordVerify,
  emailVerifyRequest,
  emailOtpVerify,
  resetPassword,
  getResellerStats,
  createReseller,
  getResellers,
  getAllTransactions,
  getNextInvoiceSerial,
  getSkipInvoiceSerial,
  requestCashPayment,
  allCashPaymentRequests,
  cashRequestApprove,
  resellerCoupons,
  resellerStats,
  getAllCompanies,
  getSuperadminSalesStats,
  getDocumentsCount,
};
