interface RegisterClientData {
  phoneNumber: string;
  roomType: string;
  roomNumber: string;
  dailyCost: number;
  returnDate: number;
  entryDate: number;
  advancedPaid: number;
  name: string;
  noOfDays: number;
}

interface CustomerData {
  addedBy: {
    id: string;
    name: string;
    role: string;
  };
  companyId: string;
  createdAt: number;
  customerId: string;
  documentEntryIds: string[];
  isActive: boolean;
  meta: RegisterClientData;
  phone: string;
  returnDate: number;
  entryDate: number;
}

interface StaffData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  email: string;
  address: string;
  password: string;
}

interface DocumentData {
  createdAt: number;
  documentId: string;
  documentType: string;
  entryId: string;
  isByOtp: boolean;
  isCustomer: boolean;
  meta: {
    ageBand: string;
    gender: string;
    mobileNumber: string;
    state: string;
    verified: string;
  };
  mode: string;
}

interface UserDetails {
  companyId: string;
  isVerified: boolean;
  name: string;
  role: string;
}

interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface DateRangeUnix {
  startDate: number;
  endDate: number;
}

interface VerificationLinkData {
  companyId: string;
  createdAt: number;
  creatorDetails: {
    name: string;
    userId: string;
  };
  documentTypes: any;
  expiresAt: number;
  isActive: boolean;
  linkId: string;
  status: string;
}

interface OrderData {
  id: string;
  entity: string;
  amount: number;
  amount_paid: number;
  amount_due: number;
  currency: string;
  attempts: number;
  notes: {
    companyId: string;
    tokenCount: number;
  };
  created_at: number;
}

interface User {}

interface Company {
  address: string;
  adminId: string;
  companyId: string;
  createdAt: number;
  disabledStaffs: any[];
  name: string;
  staffIds: any[];
  state: string;
  type: string;
  zip: string;
  city: string;
  email: string;
  isEmailVerified: string;
}
function createCompanyFromObject(obj: any): Company {
  return {
    address: obj?.address || "",
    adminId: obj?.adminId || "",
    companyId: obj?.companyId || "",
    createdAt: obj?.createdAt || 0,
    disabledStaffs: obj?.disabledStaffs || [],
    name: obj?.name || "",
    staffIds: obj?.staffIds || [],
    state: obj?.state || "",
    type: obj?.type || "",
    zip: obj?.zip || "",
    city: obj?.city || "",
    email: obj?.email || "",
    isEmailVerified: obj?.isEmailVerified || false,
  };
}

interface User {
  companyId: string;
  isVerified: boolean;
  name: string;
  role: string;
  userId: string;
  email: string;
  isEmailVerified: string;
  password: string;
}
function createUserFromObject(obj: any): User {
  return {
    companyId: obj?.companyId || "",
    isVerified: obj?.isVerified || false,
    name: obj?.name || "",
    role: obj?.role || "",
    userId: obj?.userId || "",
    email: obj?.email || "",
    isEmailVerified: obj?.isEmailVerified || false,
    password: obj?.password || "",
  };
}

interface Wallet {
  companyId: string;
  isSignupCouponApplied: boolean;
  tokens: number;
  totalMoneySpend: number;
  updatedAt: number;
}
function createWalletFromObject(obj: any): Wallet {
  return {
    companyId: obj?.companyId,
    isSignupCouponApplied: obj?.isSignupCouponApplied,
    tokens: obj?.tokens,
    totalMoneySpend: obj?.totalMoneySpend,
    updatedAt: obj?.updatedAt,
  };
}

interface Reseller {
  companyId: string;
  email: string;
  isEmailVerified: boolean;
  isVerified: boolean;
  name: string;
  role: string;
  userId: string;
}
interface Transaction {
  companyId: string;
  createdAt: number;
  currentTokens: number;
  message: string;
  moneyDue: number;
  moneySpend: number;
  status: string;
  tokenCredit: number;
  tokenDeduct: number;
  tokenDue: number;
  transactionId: string;
  triggerKey: string;
}
function createTransactionFromObject(obj: any): Transaction {
  return {
    companyId: obj.companyId,
    createdAt: obj.createdAt,
    currentTokens: obj.currentTokens,
    message: obj.message,
    moneyDue: obj.moneyDue,
    moneySpend: obj.moneySpend,
    status: obj.status,
    tokenCredit: obj.tokenCredit,
    tokenDeduct: obj.tokenDeduct,
    tokenDue: obj.tokenDue,
    transactionId: obj.transactionId,
    triggerKey: obj.triggerKey,
  };
}

interface UserWallet {
  companyId?: string;
  isSignupCouponApplied?: boolean;
  tokens?: number;
  totalMoneySpend?: number;
  updatedAt?: number;
}

namespace Timer {
  export interface OtpExpireTime {
    verifyLogin: number;
    verifyEmail: number;
    forgotPassword: number;
  }

  export function getOtpExpireTime(): OtpExpireTime {
    let result: OtpExpireTime = {
      verifyLogin: 0,
      verifyEmail: 0,
      forgotPassword: 0,
    };
    const storedPreviousTime = localStorage.getItem("otp-timer");

    if (storedPreviousTime) {
      const previousTime = JSON.parse(storedPreviousTime) as OtpExpireTime;
      result = previousTime;
    }
    return result;
  }

  export function setOtpExpireTime(time: OtpExpireTime): void {
    const timeToBeStored: string = JSON.stringify(time);
    localStorage.setItem("otp-timer", timeToBeStored);
  }
}

export type {
  UserWallet,
  OrderData,
  VerificationLinkData,
  RegisterClientData,
  CustomerData,
  StaffData,
  DocumentData,
  UserDetails,
  DateRange,
  DateRangeUnix,
  User,
  Company,
  Wallet,
  Reseller,
  Transaction,
};

export {
  Timer,
  createCompanyFromObject,
  createUserFromObject,
  createWalletFromObject,
  createTransactionFromObject,
};
