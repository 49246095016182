import * as Yup from "yup";

const convertHotelBnbsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]\d{9}$/, {
      message: "Please enter valid phone number.",
      excludeEmptyString: false,
    })
    .length(10, "Please enter 10 digit number"),
  roomType: Yup.string().required("Room type is required."),
  roomNumber: Yup.string().required("Room number is required"),
  dailyCost: Yup.number()
    .typeError("Please provide a valid rate per day amount.")
    .min(100, "Amount is too less"),
  advancedPaid: Yup.number(),
});

export { convertHotelBnbsSchema };
