import { Button } from "@radix-ui/themes";
import moment from "moment";
import React from "react";
import Modal from "react-responsive-modal";

function LinkDetails({ linkData, onClose, open }) {
  const { linkId, status, createdAt, documentTypes, expiresAt } = linkData;
  // console.log(linkData);

  return (
    <Modal open={open} onClose={onClose} center>
      <div className="modal-wrapper flex flex-col">
        <div className="modal-title flex p-6 w-full">View link details</div>
        <div className="modal-body flex flex-col p-6  w-full">
          <div className="flex items-center gap-7 p-3">
            <span>Verification link id</span>
            <p>{linkId}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Link status</span>
            <p>{status.toUpperCase()}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Link created on</span>
            <p>{moment(createdAt).toLocaleString()}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Document types</span>
            <p>{JSON.stringify(documentTypes)}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Total Required Tokens</span>
            <p>{Object.keys(documentTypes).length * 1}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Tokens used</span>
            <p>{"no_data"}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Tokens rollback</span>
            <p>{"no_data"}</p>
          </div>
          <div className="flex items-center gap-7 p-3">
            <span>Expired at</span>
            <p>{"no_data"}</p>
          </div>
        </div>

        <div
          className="modal-footer flex justify-end p-6 w-full"
          onClick={onClose}
        >
          <a className="modal-close-bt flex items-center">Close</a>
        </div>
      </div>
    </Modal>
  );
}

function DataElement(props: { children: any; title: string }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "white",
      }}
    >
      <span>{props.title}</span>
      {props.children}
    </div>
  );
}

export default LinkDetails;
