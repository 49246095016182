import { Layout } from "components";
import {
  createCoupon,
  getResellerSales,
  getCoupons,
  getResellerCoupon,
  queryKeys,
  userDetails,
} from "constant";
import moment from "moment";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import Modal from "react-responsive-modal";
import { MoonLoader, ClockLoader } from "react-spinners";
import { ImFileEmpty } from "react-icons/im";

interface CouponData {
  couponPrefix: string;
  creditToken: number;
}

function Generatecoupon() {
  const [purchaseTokenModal, setPurchaseTokenModal] = useState(false);
  const [LastEvaluatedKeys, setLastEvaluatedKey] = useState([]);

  const { data: couponsData, isLoading: isCouponsDataLoading }: any = useQuery(
    [queryKeys.GET_COUPONS],
    getCoupons
  );

  const {
    data: resellerCouponsData,
    isLoading: isResellerCouponsDataLoading,
    refetch: refetchResellerCouponsData,
  }: any = useQuery(
    [
      queryKeys.GET_RESELLER_COUPONS,
      {
        limit: 5,
        LastEvaluatedKey: LastEvaluatedKeys?.length
          ? LastEvaluatedKeys[LastEvaluatedKeys?.length - 1]
          : "",
      },
    ],
    getResellerCoupon
  );

  const { mutate: mutateCreateCoupon, isLoading: isCouponCreating } =
    useMutation({
      mutationFn: createCoupon,
      onSuccess: (data) => {
        setPurchaseTokenModal(false);
        refetchResellerCouponsData();
        toast.success(data.message);
      },
      onError: (error) => {
        toast.error(JSON.stringify(error));
      },
    });

  function handleCouponCreation(couponPrefix: string) {
    mutateCreateCoupon({
      couponPrefix: couponPrefix,
    });
  }

  return (
    <Layout>
      <div className="p-7 body-bg">
        {/* Generate coupon card */}

        <div className="all-verified-documents-wrap flex p-7 mt-2 flex-col">
          <div className="flex justify-between items-center mb-6 c-m-title">
            <h3 className="mb-3 text-xl font-semibold text-white">
              Coupons Management
            </h3>

            <button
              className="generate-new-coupon-title blue-bt"
              onClick={() => setPurchaseTokenModal(true)}
            >
              + Generate new coupon
            </button>
          </div>
          {/* Generated coupons table */}
          <div className="table-container w-full">
            <table className="w-full">
              <thead>
                <tr className="head-dashed-underline">
                  <th className="uppercase">Id</th>
                  <th className="uppercase">Created at</th>
                  <th className="uppercase">Credit token</th>
                  <th className="uppercase">Is used</th>
                  {/* <th className="uppercase">Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {resellerCouponsData?.data?.Items?.map((couponData: any) => (
                  <tr>
                    <td>
                      <span className="flex">
                        {couponData.couponId}{" "}
                        <span
                          className="mx-2 cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(couponData.couponId);
                          }}
                        >
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0" />

                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />

                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                d="M20.9983 10C20.9862 7.82497 20.8897 6.64706 20.1213 5.87868C19.2426 5 17.8284 5 15 5H12C9.17157 5 7.75736 5 6.87868 5.87868C6 6.75736 6 8.17157 6 11V16C6 18.8284 6 20.2426 6.87868 21.1213C7.75736 22 9.17157 22 12 22H15C17.8284 22 19.2426 22 20.1213 21.1213C21 20.2426 21 18.8284 21 16V15"
                                stroke="#009ef7 "
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />{" "}
                              <path
                                d="M3 10V16C3 17.6569 4.34315 19 6 19M18 5C18 3.34315 16.6569 2 15 2H11C7.22876 2 5.34315 2 4.17157 3.17157C3.51839 3.82475 3.22937 4.69989 3.10149 6"
                                stroke="#009ef7 "
                                stroke-width="1.5"
                                stroke-linecap="round"
                              />{" "}
                            </g>
                          </svg>
                        </span>
                      </span>
                    </td>
                    <td>{moment(couponData.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{couponData.creditToken}</td>
                    <td>{couponData?.isUsed ? "YES" : "NO"}</td>
                  </tr>
                ))}

                {resellerCouponsData?.data?.Items?.length == 0 && (
                  <tr>
                    <td colSpan={10}>
                      <div className="justify-center items-center flex-col flex my-5">
                        <ImFileEmpty className="text-5xl mb-4" />
                        <div className=" font-bold text-xl">
                          No records found
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {isResellerCouponsDataLoading && (
                  <tr>
                    <td colSpan={10}>
                      <div
                        style={{
                          width: "100%",
                          height: "300px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ClockLoader color="white" size="20" />{" "}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="flex back-next-bt-wrapper justify-end gap-3 items-end mt-4">
              <div
                className="flex items-center"
                onClick={() => {
                  setLastEvaluatedKey((olds: any) => {
                    const newArr = JSON.parse(JSON.stringify(olds));
                    return newArr?.length ? newArr?.slice(0, -1) : newArr;
                  });
                }}
                style={{
                  backgroundColor: LastEvaluatedKeys?.length
                    ? "#009ef7"
                    : "#2b2b40",
                  color: LastEvaluatedKeys?.length ? "white" : "#7e8299",
                }}
              >
                Back
              </div>
              <div
                onClick={() => {
                  if (resellerCouponsData?.data?.LastEvaluatedKey) {
                    setLastEvaluatedKey((olds) => {
                      return [
                        ...olds,
                        resellerCouponsData?.data?.LastEvaluatedKey,
                      ];
                    });
                  }
                }}
                className={`flex items-center`}
                style={{
                  backgroundColor: resellerCouponsData?.data?.LastEvaluatedKey
                    ? "#009ef7"
                    : "#2b2b40",
                  color: resellerCouponsData?.data?.LastEvaluatedKey
                    ? "white"
                    : "#7e8299",
                }}
              >
                Next
              </div>
            </div>

            <Toaster
              toastOptions={{
                success: {
                  duration: 3000,
                },
              }}
            />

            {/* Token purchase modal */}
            <Modal
              open={purchaseTokenModal}
              onClose={() => setPurchaseTokenModal(false)}
              center
              classNames={{
                modal: "customModal",
              }}
            >
              <div className="flex flex-col gap-4 justify-center py-4 text-white">
                {couponsData?.data?.Items?.map((token) => (
                  <Token
                    data={token}
                    onPurchase={(data) =>
                      handleCouponCreation(data.couponPrefix)
                    }
                    isLoading={isCouponCreating}
                  />
                ))}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function Token({
  data,
  onPurchase,
  isLoading,
}: {
  data: CouponData;
  onPurchase: (data: CouponData) => any;
  isLoading: boolean;
}) {
  return (
    <div className="buy-tokens-wrapper p-7">
      <div className="buy-token-box p-7 flex flex-col justify-center items-center gap-3">
        <h2>{data.couponPrefix}</h2>
        <h3>Bonus token: {data.creditToken}</h3>
        <button
          className="blue-bt w-full"
          onClick={() => onPurchase(data)}
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Generate reseller coupon"}
        </button>
      </div>
    </div>
  );
}

export { Generatecoupon };
