import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Layout } from "components";
import ActiveCustomers from "./ActiveCustomers";
import InActiveCustomers from "./InactiveCustomers";

export const dateTypes = {
  reset: "",
  createdDate: "CREATED_DATE",
  returnedDate: "RETURNED_DATE",
  entryDate: "ENTRY_DATE",
};

function CustomersPage() {
  const [refreshActiveCustomers, setRefreshActiveCustomers] = useState(false);

  function handleCustomerDelete() {
    setRefreshActiveCustomers((prev) => !prev);
  }

  return (
    <Layout>
      <div className="p-7 body-bg">
        <ActiveCustomers onCustomerDelete={handleCustomerDelete} />
        <InActiveCustomers refreshCustomers={refreshActiveCustomers} />
      </div>
    </Layout>
  );
}

export { CustomersPage };
