import React from "react";
import { DataField } from ".";
import moment from "moment";

function AdhaarOtpCard({ doc }) {
  const { addedBy, createdAt, meta } = doc;
  const { address, dob, photo, name, uid } = meta;

  return (
    <>
      <p className="adhaar-data-box flex self-start">Adhaar otp</p>
      <div className="customer-details-top">
        <DataField title="">
          <img src={photo} />
        </DataField>
        <DataField title="Name">{name}</DataField>
        <DataField title="Id">{uid}</DataField>
        <DataField title="Date of birth">{dob}</DataField>
        <DataField title="Document verified on">
          {moment(createdAt).format("DD/MM/YYYY")}
        </DataField>
        <DataField title="Document verified by">
          {`${addedBy.name} (${addedBy.role})`}
        </DataField>
        <DataField title="Address">{address}</DataField>
      </div>
    </>
  );
}

export default AdhaarOtpCard;
