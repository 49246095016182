import { useState, useRef } from "react";
import * as Form from "@radix-ui/react-form";
import toast, { Toaster } from "react-hot-toast";
import { useMutation } from "react-query";
import { loginUser, verifyUser, cookie } from "constant";
import { MoonLoader } from "react-spinners";
import { VerifyOtp } from "components";
import { localStorageItems } from "constant/localStorageItem";

function LoginPage() {
  const [showVerifyCard, setShowVerifyCard] = useState(false);
  const [otpExpireTime, setOtpExpireTime] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");

  let otpValidTill = 0;

  const phoneRef: any = useRef();
  const passRef: any = useRef();

  const { mutate: mutateLogin, isLoading: isLoggingin } = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      if (data?.data?.accessToken?.length) {
        cookie.set("accessToken", data?.data?.accessToken, {
          path: "/",
          expires: new Date(Date.now() + 12 * 60 * 60 * 1000),
        });
      }

      if (data?.data?.isVerified === true) {
        window.location.replace("/");
      } else if (data?.data?.isVerified === false) {
        toast.success("OTP sent to your phone number");
        const { otpExpireTime: serverOtpExpireTime } = data?.data;

        localStorage.setItem(localStorageItems.USER_LOGIN_PHONE, phoneNumber);
        localStorage.setItem(
          "otp-time-map",
          JSON.stringify({
            loginVerify: {
              [phoneNumber]: serverOtpExpireTime,
            },
          })
        );

        setOtpExpireTime(serverOtpExpireTime);

        setShowVerifyCard(true);
      } else {
        toast.error(data?.message);
      }
    },
    onError: () => {
      toast.error("Failed to login.");
    },
  });

  const relogin = () => {
    mutateLogin({
      queryKey: [
        "",
        {
          phone: phoneRef.current?.value,
          password: passRef.current?.value,
        },
      ],
    });
  };

  const submitHandler = async (event) => {
    console.log("form submit hello");
    event.preventDefault();
    const data = new FormData(event.target);
    const formObject = Object.fromEntries(data.entries());

    if (showVerifyCard) {
      // mutateVerify({ queryKey: ["", formObject] });
    } else {
      mutateLogin({ queryKey: ["", formObject] });
    }
    return false;
  };

  const { mutate: mutateVerify, isLoading: isVerifing } = useMutation({
    mutationFn: verifyUser,
    onSuccess: (data) => {
      console.log("Response data here", data);
      if (data?.data?.isVerified) {
        toast.success("User verified successfully.");

        localStorage.removeItem(localStorageItems.USER_LOGIN_PHONE);
        localStorage.setItem(
          "otp-time-map",
          JSON.stringify({
            loginVerify: {},
          })
        );

        relogin();
      } else {
        toast.error("Unable to verify user");
      }
    },
    onError: () => {
      toast.error("Failed to verify user.");
    },
  });

  otpValidTill = (otpExpireTime - Date.now()) / 1000;

  return (
    <div className="login-box">
      <a href="" className="logo flex items-center justify-center">
        <img src="/images/spaceshift_logo.png" alt="" />
      </a>
      <div className="login-box-inner">
        <Toaster />
        <div className={` ${!showVerifyCard ? "hidden" : ""}`}>
          <VerifyOtp
            submitHandler={(otp) => {
              mutateVerify({ otp: otp });
            }}
            isLoading={isVerifing || isLoggingin}
            resendOtpHandler={relogin}
            otpExpireMinutes={Math.floor(otpValidTill / 60)}
            onTimerEnd={() => {
              localStorage.setItem(
                "otp-time-map",
                JSON.stringify({
                  loginVerify: {
                    [phoneNumber]: "",
                  },
                })
              );
              // localStorage.removeItem(localStorageItems.USER_PHONE);
              // setShowVerifyCard(false);
            }}
            otpExpireSeconds={Math.floor(otpValidTill % 60)}
          />
        </div>

        <Form.Root
          className={`input-box ${showVerifyCard ? "hidden" : ""}`}
          onSubmit={submitHandler}
        >
          <Form.Field className="mb-[10px] grid" name="phone">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                Phone number
              </Form.Label>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please enter your phone number
              </Form.Message>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match={(value, formData) =>
                  value.length !== 10 || !!!value.match(/^-?\d+$/)
                }
              >
                Please provide a valid phone number
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input
                className=""
                type="number"
                required
                ref={phoneRef}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Control>
          </Form.Field>

          <Form.Field className="mb-[10px] grid" name="password">
            <div className="flex items-baseline justify-between">
              <Form.Label className="text-[15px] font-medium leading-[35px] text-black">
                Password
              </Form.Label>
              <Form.Message
                className="text-[13px] text-black opacity-[0.8]"
                match="valueMissing"
              >
                Please enter password
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input className="" type="password" required ref={passRef} />
            </Form.Control>
          </Form.Field>

          <Form.Submit asChild disabled={isLoggingin}>
            <button className="blue-bt mt-5 w-full ml" disabled={isLoggingin}>
              
              {isLoggingin ? (
                <MoonLoader color="rgba(12, 15, 55, 1)" size="20" />
             ) : ( 
                <span> Login</span>
               )} 
            </button>
          </Form.Submit>

          <div className="flex flex-col items-center justify-center gap-3 pt-5">
            <a
              href="/forgot-password"
              className="text-sm capitalize hover:text-blue-300"
            >
              forgot password
            </a>
            <span className="text-sm">
              New here?{" "}
              <a href="/signup">
                {" "}
                <b className="capitalize text-blue-600"> signup</b>
              </a>
            </span>
          </div>
        </Form.Root>
      </div>
    </div>
  );
}

export { LoginPage };
